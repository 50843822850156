import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from '../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import Loading from '../images/icon/spinner.jpg'
import { categoriesDataUpdate, itemsDataUpdate, cartDraftData, cartDraftIndex, setConfigObj, setBusinessData } from '../../features/Data/dataSlice'
import { showAppToast } from '../../features/utilities/appToastSlice'
import { showShopStatusModal } from '../../features/Offcanvas/shopStatusModalSlice'

const Load = ({
  categoriesDataUpdate,
  itemsDataUpdate,
  cartDraftData,
  cartDraftIndex,
  showShopStatusModal,
  setConfigObj,
  setBusinessData,
  businessData,
  authToken
}) => {
  const [branchData, setBranchData] = useState(null)
  const [businessTempData, setBusinessTempData] = useState(null)
  const [serverActiveOrders, setServerActiveOrders] = useState(null)
  const prevState = localStorage.getItem('prevState')
  localStorage.setItem('kotNumber', 0)
  const prevBillNo = localStorage.getItem('billNo')
  useEffect(() => {
    axios.get('/BusinessLocations/getConfig').then(res => {
      if (res.data.message === 'Success') {
        if (prevBillNo === null || prevBillNo === undefined || prevBillNo === '') {
          localStorage.setItem('billNo', res.data.data.BillNo)
        }
        setConfigObj({configObj: res.data.data})
      }
    })
  }, [prevBillNo])
  let navigate = useNavigate()
    useEffect(() => {
      axios.get('/Invoices').then(res => {
        if (res.data.message === 'Success') {
            setServerActiveOrders(res.data.data)
        }
      })
      // setTimeout(() => {
        let tempList = []
        let defObj = {
            "OfflineInvoiceNumber": '',
            "Orders":[{
                "Items":[],
                "TotalQty":0,
                "Subtotal":0,
                "TotalTax":{
                    "cgst": 0,
                    "sgst":0
                },
                "Total":0,
                "Type":'',
                "DateTime":null,
                "OrderNumber":'',
                "OrderStatus": 0
            }],
            "Currency":"INR",
            "Phone":null,
            "Date": null,
            "UserName":"",
            "BalanceAmount":0,
            "PaymentMethod":"",
            "OrderType": '',
            "Amount": 0,
            "TransactionDate": null,
            "Customer_ID": "",
            "Status": -2,
            "TotalDiscount": 0
        }
        if (serverActiveOrders !== null) {
          {serverActiveOrders.map((v,k) => {
            v.Orders.map((v1,k1) => {
              v1.OrderStatus = 1
            })
            v.TotalQuantity = v.TotalItems
            if (v.Orders.length > 0) {
              tempList.push(v)
            }
          })}
          for (let i = serverActiveOrders.length; i <= 25; i++) {
            tempList.push(defObj)
          }
        } else {
          for (let i = 0; i <= 25; i++) {
            tempList.push(defObj)
          }
        }
        localStorage.setItem('CartDraft', JSON.stringify(tempList))
        cartDraftData({draftData: tempList})
        cartDraftIndex({draftIndex: null})
      // }, 1000)
    }, [serverActiveOrders])

    useEffect(() => {
      axios.get('/ItemCategories/business').then(res => {
        if (res.data.message === 'Success') {
          categoriesDataUpdate({categories: res.data.data})
          axios.get('/Items/business').then(res => {
            if (res.data.message === 'Success') {
              if (res.data.data.length > 0) {
                itemsDataUpdate({items: res.data.data})
                navigate((prevState === null) ? '/newOrder'  : '/' + prevState)
              } else {
                navigate('/setup')
              }
            } else {
              showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
            }
          })
        } else {
          showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
        }
      })
    }, [])

    useEffect(() => {
      let shopStatus = localStorage.getItem('shopStatus')
      if (shopStatus === 'Close') {
        showShopStatusModal({shopStatusModalVisible: true})
      }
    }, [])

    useEffect(() => {
      const tokenData = jwt_decode(authToken)
      axios.get('/Businesses').then(res => {
        if (res.data.message === 'Success') {
          if (businessData === null) {
            setBusinessData({businessData: res.data.data})
          }
          res.data.data.map((value, key) => {
              value.Branches.map((v,k) => {
                  if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
                      setBranchData(v)
                      setBusinessTempData(value)
                  }
              })
          })
        } else {
            // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
        }
    })
    }, [])
  return (
    <div id="appCapsule" className="pt-5">
      <div className="error-page d-flex align-items-center justify-content-center" style={{height: '550px', padding: '0px', maxWidth: 'initial', flexFlow: 'column'}}>
        <div className="icon-box text-danger">
          <img
            src={Loading}
            alt="Brand"
            width="92px"
            style={{animation: 'spin 1s linear infinite'}}
          />
        </div>
        <button className="btn btn-light btn-lg rounded mb-1 fs-4" disabled>
          {/* <span
            className="spinner-grow spinner-grow-sm me-05"
            role="status"
            aria-hidden="true"
          ></span> */}
          Loading...
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth, data }) => {
  const { authToken } = auth
  const { businessData } = data
  return {
    authToken,
    businessData
  }
}

const mapDispatchToProps = {
  categoriesDataUpdate,
  showAppToast,
  itemsDataUpdate,
  cartDraftData,
  cartDraftIndex,
  showShopStatusModal,
  setConfigObj,
  setBusinessData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Load)
