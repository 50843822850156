import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideQuantityOffcanvas } from '../../../features/Offcanvas/quantityOffcanvasSlice'
import { updateCart, updateQuantity, updatePrice } from '../../../features/state/checkoutItemsSlice'
import { removeItem, cartDraftData } from '../../../features/Data/dataSlice'

const QuantityOffcanvas = ({
    quantityOffcanvasVisible,
    hideQuantityOffcanvas,
    checkoutItemIndex,
    checkoutItemList,
    updateQuantity,
    updateCart,
    price,
    updatePrice,
    removeItem,
    draftData,
    draftIndex,
    odIndex,
    cartDraftData
}) => {
    console.log(draftIndex, odIndex, draftData)
    let checkoutItem = checkoutItemList[checkoutItemIndex]

    const removeItemFromChecklist = ()=>{
        let itemList = [...checkoutItemList]
        console.log(itemList, checkoutItemList)
        itemList.splice(checkoutItemIndex,1)
        let tempCartDraftData = JSON.parse(JSON.stringify(draftData))
        tempCartDraftData[draftIndex].Orders[odIndex].Items.splice(checkoutItemIndex,1)
        console.log(tempCartDraftData)
        
        cartDraftData({draftData: tempCartDraftData})
        updateCart(itemList)
        updatePrice({
            subtotal : price?.subtotal - checkoutItem?.item?.MRP*checkoutItem?.quantity,
            cgst : 0,
            sgst : 0
          })
        hideQuantityOffcanvas()
    }

    const updateQuantityOfItem = (quantity)=>{
        console.log(checkoutItemIndex, price?.subtotal + checkoutItem?.item?.MRP*(quantity-checkoutItem?.quantity))
        // removeItem({itemKey:checkoutItemIndex})
        let itemList = JSON.parse(JSON.stringify(checkoutItemList))
        console.log(itemList)
        itemList[0].item.quantity = parseInt(quantity)
        console.log(itemList)
        updateCart(itemList)
        updateQuantity({checkoutItemIndex:checkoutItemIndex,quantity:quantity})
        updatePrice({
            subtotal : price?.subtotal + checkoutItem?.item?.MRP*(quantity-checkoutItem?.quantity),
            cgst : 0,
            sgst : 0
        })



        let tempCartDraftData = JSON.parse(JSON.stringify(draftData))
        console.log(draftData, tempCartDraftData, draftIndex, odIndex, tempCartDraftData[draftIndex].Orders[odIndex].Items[checkoutItemIndex])
        tempCartDraftData[draftIndex].Orders[odIndex].Items[checkoutItemIndex].quantity = parseInt(quantity)
        tempCartDraftData[draftIndex].Amount = 0
        tempCartDraftData[draftIndex].BalanceAmount = 0
        tempCartDraftData[draftIndex].Orders[odIndex].Items.map((v,k) => {
            console.log(v.quantity, v.MRP)
            tempCartDraftData[draftIndex].Amount += parseInt(v.quantity) * parseInt(v.MRP)
            tempCartDraftData[draftIndex].BalanceAmount += parseInt(v.quantity) * parseInt(v.MRP)
        })
        console.log(tempCartDraftData)
        cartDraftData({draftData: tempCartDraftData})
    }

    return (
        <div>
            <Modal show={quantityOffcanvasVisible} centered={true}>
                <Modal.Header style={{border: 'none', position: 'relative'}}>
                    {/* <div class="cursor-pointer" style={{position: 'absolute', right: '-13px', top: '-13px'}} onClick={() => {hideQuantityOffcanvas()}}>
                        <ion-icon name="close-outline" class="fs-4 rounded-circle text-dark" role="img" style={{background: '#ffffff7d'}}></ion-icon>
                    </div> */}
                    <div class="row w-100">
                        <div class="col-12 col-md-12">
                            <span>{(checkoutItem !== null || checkoutItem !== undefined) ? checkoutItem?.item?.ItemTitle : 'Quantity'} <small class="text-muted">(Qty)</small></span>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body class="pt-0 modal-body">
                    <div class="section">
                        <div class="row mb-1">
                            {/* <div class="col-12 col-md-12 d-flex justify-content-center">
                                <small><span class="text-secondary">Available Stock:</span> 36</small>
                            </div> */}
                            <div class="col-12 col-md-12">
                                <form class="search-form">
                                    <div class="form-group searchbox d-flex justify-content-center">
                                        <input type="number" class="form-control w-25 mb-1" placeholder="Quantity" min="1" value={checkoutItem?.quantity} onChange={(e)=>updateQuantityOfItem(e.target.value)}/>
                                        <i class="input-icon" style={{left: '38%'}}>
                                            <ion-icon name="cube-outline" class="fs-5"></ion-icon>
                                        </i>
                                        {/* <i class="input-icon" style={{right: '25%', left: 'auto'}}>
                                            <select class="form-control p-1 pe-0 pb-0 rounded-0" id="city5">
                                                <option value="0">gm</option>
                                                <option value="1">kg</option>
                                                <option value="2">ml</option>
                                                <option value="3">l</option>
                                            </select>
                                        </i> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="text-center mb-1">
                            <span class="badge bg-secondary me-1 fs-6" style={{cursor: 'pointer'}} onClick={()=>updateQuantityOfItem(Number(checkoutItem?.quantity)+1)}>+ 1</span>
                            <span class="badge bg-secondary me-1 fs-6" style={{cursor: 'pointer'}} onClick={()=>updateQuantityOfItem(Number(checkoutItem?.quantity)+10)}>+ 10</span>
                            <span class="badge bg-secondary me-1 fs-6" style={{cursor: 'pointer'}}onClick={()=>updateQuantityOfItem(Number(checkoutItem?.quantity)+50)}>+ 50</span>
                            <span class="badge bg-secondary me-1 fs-6" style={{cursor: 'pointer'}}onClick={()=>updateQuantityOfItem(Number(checkoutItem?.quantity)+100)}>+ 100</span>
                            <span class="badge bg-secondary me-1 fs-6" style={{cursor: 'pointer'}}onClick={()=>updateQuantityOfItem(Number(checkoutItem?.quantity)+1000)}>+ 1000</span>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-12 d-flex justify-content-center">
                                <button class="btn btn-outline-danger btn-sm me-1" onClick={removeItemFromChecklist}>
                                    <ion-icon name="trash-sharp" class="fs-6 md hydrated text-danger" role="img" aria-label="trash-sharp"></ion-icon> Remove Item
                                </button>
                                <button class="btn btn-success btn-sm me-1" onClick={() => {hideQuantityOffcanvas()}}>
                                    <ion-icon name="checkmark-circle-outline" class="fs-6 md hydrated" role="img" aria-label="checkmark-circle"></ion-icon> Done
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ quantity, checkoutItems, data }) => {
    const {
        quantityOffcanvasVisible,
        checkoutItemIndex
    } = quantity
    const {
        checkoutItemList,
        price
    } = checkoutItems
    const {
        draftData,
        draftIndex,
        odIndex
    } = data
    return {
        quantityOffcanvasVisible,
        checkoutItemIndex,
        checkoutItemList,
        price,
        draftData,
        draftIndex,
        odIndex
    }
  }
  
  const mapDispatchToProps = {
    hideQuantityOffcanvas,
    updateCart,
    updateQuantity,
    updatePrice,
    removeItem,
    cartDraftData
  }

export default connect(mapStateToProps, mapDispatchToProps)(QuantityOffcanvas)