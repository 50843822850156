import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import axios from '../../AxiosConfig'
import NonVeg from '../images/icon/non-veg.png'
import { showAddSetupModal } from '../../features/Offcanvas/addSetupModalSlice'
import AddSetupModal from '../layout/Sidebar/AddSetupModal'
import { setTempSetupObj, setAllCategoriesStore, setAllItemsStore } from '../../features/Data/dataSlice'
import SearchWithButtons from './Components/SearchWithButtons'

const BusinessSetup = ({
    showAddSetupModal,
    setTempSetupObj,
    tempSetupObj,
    setAllCategoriesStore,
    allCategoriesStore,
    setAllItemsStore,
    allItemsStore,
    itemName,
    items
}) => {
    const [allItems, setAllItems] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [filteredCategory, setFilteredCategory] = useState('')
    const [categoryID, setCategoryID] = useState(null)
    const [currentCategoryName, setCurrentCategoryName] = useState('')
    const [finalObj, setFinalObj] = useState({})
    let tempObj = {}
    let navigate = useNavigate()
    useEffect(() => {
        axios.get('/Items/business').then(res => {
            if (res.data.message === 'Success') {
              if (res.data.data.length > 0) {
                localStorage.removeItem('prevState')
                navigate('/load')
              } else {
              }
            } else {
            //   showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
            }
        })
    }, [])
    useEffect(() => {
        let tempCategoryList = []
        let tempItemList = []
        axios.get('/ItemCategories').then(res => {
            if (res.data.message === 'Success') {
                setAllCategories(res.data.data)
                setAllCategoriesStore({allCategoriesStore: res.data.data})
                tempCategoryList = JSON.parse(JSON.stringify(res.data.data))
                axios.get('/Items').then(res => {
                    if (res.data.message === 'Success') {
                        tempItemList = res.data.data
                        tempCategoryList.map((v,k) => {
                            tempCategoryList[k].count = 0
                            tempItemList.map((v1,k1) => {
                                tempItemList[k1].activeItem = true
                                if (v.ID === v1.Item_CategoryID) {
                                    tempItemList[k1].CatgeoryName = v.Name
                                    tempCategoryList[k].count += 1
                                }
                            })
                        })
                        if (tempItemList) {
                            let temObj2 = JSON.parse(JSON.stringify(tempObj))
                            tempItemList.map((v,k) => {
                                console.log(tempObj, tempItemList, v.CatgeoryName)
                                if (temObj2.hasOwnProperty(v.CatgeoryName)) {
                                    temObj2[v.CatgeoryName].Items.push(v)
                                } else {
                                    temObj2[v.CatgeoryName] = {
                                        'Items' : [v],
                                        'CategoryName' : (temObj2[v.CatgeoryName]?.CategoryName) ? temObj2[v.CatgeoryName].CategoryName : ''
                                    }
                                }
                            })
                            setTempSetupObj({tempSetupObj: temObj2})
                        }
                        setAllItems(tempItemList)
                        setAllItemsStore({allItemsStore: tempItemList})
                        setAllCategoriesStore({allCategoriesStore: tempCategoryList})
                    } else {
                    //   showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
                    }
                })
            } else {
                // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
            }
        })
    }, [])

    useEffect(() => {
        if (allItemsStore.length > 0) {
            allItemsStore.map((v,k) => {
                if (tempObj.hasOwnProperty(v.CatgeoryName)) {
                    tempObj[v.CatgeoryName].Items.push(v)
                } else {
                    tempObj[v.CatgeoryName] = {
                        'Items' : [v],
                        'CategoryName' : (tempObj[v.CatgeoryName]?.CategoryName) ? tempObj[v.CatgeoryName].CategoryName : ''
                    }
                }
            })
            setFinalObj(tempObj)
            setTempSetupObj({tempSetupObj: tempObj})
        }
    }, [])

    let editableObj = {}
    
    const selectedItem = (ID, value) => {
        setCurrentCategoryName(value.CatgeoryName)
        let setActiveItem = JSON.parse(JSON.stringify(allItemsStore))
        setActiveItem[ID].activeItem = !setActiveItem[ID].activeItem
        setAllItems(setActiveItem)
        setAllItemsStore({allItemsStore: setActiveItem})
        setFinalObj(tempSetupObj)
        console.log(finalObj, tempSetupObj)
        let editableObj = Object.assign(JSON.parse(JSON.stringify(finalObj)), tempSetupObj)
        let tempObj = JSON.parse(JSON.stringify(editableObj))
        if (setActiveItem[ID].activeItem) {
            if (editableObj[value.CatgeoryName]) {
                tempObj[value.CatgeoryName].Items.push(value)
            } else {
                tempObj[value.CatgeoryName] = {
                    'Items' : [value],
                    'CategoryName' : (tempObj[value.CatgeoryName]?.CategoryName) ? tempObj[value.CatgeoryName].CategoryName : ''
                }
            }
            setFinalObj(tempObj)
            setTempSetupObj({tempSetupObj: tempObj})
        } else {
            let tempItemList = JSON.parse(JSON.stringify(editableObj[value.CatgeoryName])) 
            if (editableObj[value.CatgeoryName]) {
                editableObj[value.CatgeoryName].Items.map((v,k) => {
                    console.log(value.ID, v.ID)
                    if (value.ID === v.ID) {
                        console.log('IF')
                        tempItemList.Items.splice(k, 1)
                        console.log(tempItemList)
                        editableObj[value.CatgeoryName] = tempItemList
                    }
                })
                if (editableObj[value.CatgeoryName].Items.length === 0) {
                    delete editableObj[value.CatgeoryName]
                }
            }
            setFinalObj(editableObj)
            setTempSetupObj({tempSetupObj: editableObj})
        }
    }
    const add = (type) => {
        showAddSetupModal({addSetupModalVisible: true, type: type, setupData: null, actionType: 'add', arrKey: filteredCategory.length})
    }
    const updateItem = (e, type, data, key) => {
        e.stopPropagation()
        showAddSetupModal({addSetupModalVisible: true, type: type, setupData: data, actionType: '', arrKey: key})
    }
    const update = (e,type, data) => {
        e.stopPropagation()
        showAddSetupModal({addSetupModalVisible: true, type: type, setupData: data, actionType: 'rename', arrKey: null})
    }
    const remove = (e,type, data) => {
        e.stopPropagation()
        showAddSetupModal({addSetupModalVisible: true, type: type, setupData: data, actionType: 'remove', arrKey: null})
    }
    const filterItems = (ID) => {
        setCategoryID(ID)
    }
    useEffect(() => {
        if (categoryID !== null) {
            if (allItemsStore) {
                let filtered;
                filtered = allItemsStore.filter((item) => categoryID === item.Item_CategoryID);
                setFilteredCategory(filtered)
            }
        } else if (itemName !== null && itemName !== undefined && itemName !== '') {
            if (allItemsStore) {
                let filtered;
                filtered = allItemsStore.filter((item) => item.Title.toLowerCase().includes(itemName.toLowerCase()));
                setFilteredCategory(filtered)
            }
        } else {
            setFilteredCategory(allItemsStore)
        }
    }, [allItemsStore, categoryID, itemName])
    const handleSubmitSetup = () => {
        // console.log(allItemsStore)
        console.log(tempSetupObj)
    }
    return (
        <div id="appCapsule" class="pb-0">
            <div class="right custom-splide d-flex justify-content-end custom-header-width" style={{position: 'fixed', top: '12px', right: '8px', zIndex: 9999}} onClick={handleSubmitSetup}>
                <button class="btn btn-success btn-sm">Complete Setup <ion-icon name="arrow-forward-outline" class="fs-6"></ion-icon></button>
            </div>
            <div class="section full">
                <div class="mt-1">
                    <SearchWithButtons pageName={'BusinessSetup'}></SearchWithButtons>
                </div>
                <div class="row">
                    <div class="col-4 col-md-2 responsive-height">
                        <a href="javascript:void(0)" class="btn btn-sm btn-outline-primary px-2 justify-content-between d-flex m-auto mt-1 mb-1" style={{fontSize: '14px'}} onClick={() => {filterItems(null)}}>
                            Show All
                            <span class="badge bg-primary" style={{height: '14px', fontSize: '10px'}}>{allItems.length}</span>
                        </a>
                        <ul class="listview link-listview bg-transparent">
                            {allCategoriesStore.map(value => {
                                return <>
                                    {(value.count > 0 || value.count === false) &&
                                        <li class={(categoryID === value.ID) ? "bg-transparent custom-chevron-alignment" : "bg-white custom-chevron-alignment"} style={{paddingBottom: '3px'}} onClick={() => {filterItems(value.ID)}}>
                                            <a href="javascript:void(0)" class="px-2 pb-0" style={{fontSize: '13px',minHeight: '24px', paddingTop: '2px'}}>
                                                <span>{(value.Renamed) ? value.Renamed : value.Name}</span>
                                                <span class="badge bg-primary" style={{height: '10px',fontSize: '7px'}}>{value.count}</span>
                                            </a>
                                            <div class="fs-10 ps-1 d-none d-sm-block">
                                                <span class="btn btn-sm btn-link text-secondary fs-10 ps-0" style={{height: '14px', marginRight: '2px'}} onClick={(e) => {update(e,'Category', value)}}><u>Rename</u></span> 
                                                {/* <span class="btn btn-sm btn-link text-danger fs-10 ps-0" style={{height: '14px'}} onClick={(e) => {remove(e,'Category', value)}}><u>Remove</u></span> */}
                                            </div>
                                            <div class="fs-10 ps-1 d-block d-sm-none">
                                                <span class="btn btn-sm btn-link text-secondary fs-10 ps-0" style={{height: '14px', marginRight: '2px'}} onClick={(e) => {update(e,'Category', value)}}><ion-icon name="create-outline" class="fs-6"></ion-icon></span> 
                                                <span class="btn btn-sm btn-link text-danger fs-10 ps-0" style={{height: '14px'}} onClick={(e) => {remove(e,'Category', value)}}><ion-icon name="trash-bin-outline" class="fs-6"></ion-icon></span>
                                            </div>
                                        </li>
                                    }
                                </>
                            })}
                        </ul>
                    </div>
                    <div class="col-8 col-md-10 responsive-height">
                        <div class="row mt-2">
                            {(filteredCategory !== null && filteredCategory !== undefined && filteredCategory !== '') &&
                                <>
                                    {filteredCategory.map((v,k) => {
                                        return <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-2" style={{position: 'relative'}}>
                                            <div class="card product-card shadow-none" style={{backgroundColor: 'transparent', overflow: 'hidden', border: 'solid 2px', borderColor: (v.activeItem) ? '#e3c20e' : 'gainsboro', height: '100%'}} onClick={() => {selectedItem(k, v)}}>
                                                <div class="card-body">
                                                    <div>
                                                        {v.ImageURL &&
                                                            <img src={v.ImageURL} class="image" alt="product image" style={{objectFit:'cover'}} />
                                                        }
                                                        {(v.ImageURL === null || v.ImageURL === undefined || v.ImageURL === '') &&
                                                            <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" class="image" alt="product image" style={{objectFit:'cover'}} />
                                                        }
                                                        <img src={NonVeg} width="18px" style={{position: 'absolute', top: 13, right: 13, backgroundColor: 'white'}} />
                                                    </div>
                                                    <h2 class="title d-flex justify-content-between" style={{fontSize: '13px'}}>
                                                        <a href="javascript:void(0)">
                                                            <div class="fw-bolder" style={{fontSize: '11px'}}>
                                                                {v.Title}
                                                                <br />
                                                                <small>{v.CatgeoryName}</small>
                                                            </div>
                                                            <div class="position-absolute" style={{fontSize: '8px', height: '14px', bottom: '12px', right: '12px'}}> {(v.SaleUnit) ? v.SaleUnit : ''}</div>
                                                        </a>
                                                    </h2>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12">
                                                            <p class="price mb-0 text-secondary" style={{fontSize: '13px'}}>₹{v.MRP}</p>
                                                        </div>
                                                    </div>
                                                    <button class="mt-1 btn btn-primary btn-sm btn-block" onClick={(e) => {updateItem(e, 'Item', v, k)}}>
                                                        Edit Item
                                                    </button>
                                                </div>
                                            </div>
                                            <div style={{position: 'absolute', top: '-8px', left: '0px'}}>
                                                <span class="badge p-0" style={{fontSize: '16px', backgroundColor: (v.activeItem) ? '#e3c20e' : 'gainsboro'}}>
                                                    <ion-icon name="checkmark-outline"></ion-icon>
                                                </span>
                                            </div>
                                        </div>
                                    })}
                                    {(filteredCategory.length === 0) &&
                                        <div class={`col-12 col-md-12 mb-1 mt-5 d-flex align-items-center justify-content-center flex-column`}>
                                            <h2 class="text-danger">No Items Found</h2>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div class="fab-button text bottom-center responsive-bottom-fab" style={{transform: 'translate(-103%, 0%)'}}>
                <button class="fab pe-2 ps-2" style={{height: '36px', fontFamily: "'Inter', sans-serif", fontSize: '12px'}} onClick={() => {add('Category')}}>
                    <ion-icon name="add-outline" class="fs-5"></ion-icon> Add Category
                </button>
            </div>
            <div class="fab-button text bottom-center responsive-bottom-fab" style={{transform: 'translate(3%, 0%)'}}>
                <button class="fab pe-2 ps-2" style={{height: '36px', fontFamily: "'Inter', sans-serif", fontSize: '12px'}} onClick={() => {add('Item', null)}}>
                    <ion-icon name="add-outline" class="fs-5"></ion-icon> Add Item
                </button>
            </div>
            <AddSetupModal allCategories={allCategoriesStore} allItems={allItemsStore}></AddSetupModal>
        </div>
    )
}

const mapStateToProps = ({ data }) => {
    const {
        tempSetupObj,
        allCategoriesStore,
        allItemsStore,
        itemName,
        items
    } = data
    return {
        tempSetupObj,
        allCategoriesStore,
        allItemsStore,
        itemName,
        items
    }
}
  
const mapDispatchToProps = {
    showAddSetupModal,
    setTempSetupObj,
    setAllCategoriesStore,
    setAllItemsStore
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSetup)
