import React, { useEffect } from 'react'
import { connect } from "react-redux"
import { showQuantityOffcanvas } from '../../../features/Offcanvas/quantityOffcanvasSlice'
import { updateCart, updatePrice } from '../../../features/state/checkoutItemsSlice'
import { cartDraftData, removeItem, itemsDataUpdate } from '../../../features/Data/dataSlice'
import { updateStock, updateStockPrice } from '../../../features/Data/stockSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import Veg from '../../images/icon/veg.png'
import NonVeg from '../../images/icon/non-veg.png'

const CheckoutItemList = ({
    showQuantityOffcanvas,
    itemList,
    alternateTextVisible,
    updateCart,
    updatePrice,
    price,
    draftData,
    draftIndex,
    cartDraftData,
    odIndex,
    itemKey,
    itemValue,
    removeItem,
    stockActionColor,
    updateStock,
    updateStockPrice,
    items,
    itemsDataUpdate,
    showAppToast,
    checkoutItemIndex
}) => {
    console.log(itemList)
    const stateName = window.location.pathname.split('/')
    const handleQuantity = (index) => {
        showQuantityOffcanvas({quantityOffcanvasVisible: true, checkoutItemIndex: index})
    }

    let tempItemList = JSON.parse(JSON.stringify(items))
    console.log(tempItemList)
    const handleQty = (key, actionType, customValue, itemID) => {
        if (stateName[1] === 'newOrder') {
            let tempCheckOutObj = JSON.parse(JSON.stringify(itemList))
            let finalTotal
            let tempObj = JSON.parse(JSON.stringify(draftData))
            let finalCustomQty = 0
            let finalCustomAmount = 0
            // console.log(tempCheckOutObj,tempCheckOutObj[key].quantity)
            if (actionType === 'add') {
                console.log(key, draftIndex)
                tempCheckOutObj[key].quantity = parseInt(tempCheckOutObj[key].quantity) + 1
                finalTotal = price.subtotal + parseInt(tempCheckOutObj[key].item['MRP'])
                tempObj[draftIndex].TotalQuantity = tempObj[draftIndex].TotalQuantity + 1
                tempObj[draftIndex].Amount = finalTotal
                tempObj[draftIndex].BalanceAmount = finalTotal
                tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[key].quantity = tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[key].quantity + 1
                console.log(tempCheckOutObj)
                tempItemList.map((v,k) => {
                    if (v.Sizes.length > 0) {
                        v.Sizes.map((v1,k1) => {
                            if (v1.ID === itemID) {
                                v1.SalesQty = parseInt(v1.SalesQty) - 1
                            }
                        })
                    } else {
                        if (v.ID === itemID) {
                            v.SalesQty = parseInt(v.SalesQty) - 1
                        }
                    }
                })
                itemsDataUpdate({items: tempItemList})
            } else if (actionType === 'minus') {
                if (tempCheckOutObj[key].quantity === 1) {
                    tempObj[draftIndex].TotalQuantity = tempObj[draftIndex].TotalQuantity - 1
                    tempObj[draftIndex].Amount = tempObj[draftIndex].Amount - parseInt(tempCheckOutObj[key].item['MRP'])
                    tempObj[draftIndex].BalanceAmount = tempObj[draftIndex].BalanceAmount - parseInt(tempCheckOutObj[key].item['MRP'])
                    finalTotal = tempObj[draftIndex].Amount
                    tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[key].quantity = tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items.splice(key,1)

                    tempItemList.map((v,k) => {
                        if (v.Sizes.length > 0) {
                            v.Sizes.map((v1,k1) => {
                                if (v1.ID === itemID) {
                                    v1.SalesQty = parseInt(v1.SalesQty) + 1
                                }    
                            })
                        } else {
                            if (v.ID === itemID) {
                                v.SalesQty = parseInt(v.SalesQty) + 1
                            }
                        }
                    })
                    itemsDataUpdate({items: tempItemList})
                    tempCheckOutObj.splice(key, 1)
                } else {
                    tempCheckOutObj[key].quantity = tempCheckOutObj[key].quantity - 1
                    tempObj[draftIndex].TotalQuantity = tempObj[draftIndex].TotalQuantity - 1
                    tempObj[draftIndex].Amount = tempObj[draftIndex].Amount - parseInt(tempCheckOutObj[key].item['MRP'])
                    tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[key].quantity = tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[key].quantity - 1
                    tempObj[draftIndex].BalanceAmount = tempObj[draftIndex].BalanceAmount - parseInt(tempCheckOutObj[key].item['MRP'])
                    finalTotal = tempObj[draftIndex].Amount

                    tempItemList.map((v,k) => {
                        if (v.Sizes.length > 0) {
                            v.Sizes.map((v1,k1) => {
                                if (v1.ID === itemID) {
                                    v1.SalesQty = parseInt(v1.SalesQty) + 1
                                }    
                            })
                        } else {
                            if (v.ID === itemID) {
                                v.SalesQty = parseInt(v.SalesQty) + 1
                            }
                        }
                    })
                    itemsDataUpdate({items: tempItemList})
                }
            } else if (actionType === 'custom') {
                tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[key].quantity = parseInt(customValue)
                tempCheckOutObj[key].quantity = parseInt(customValue)
                console.log(tempObj)
                for (let i = 0; i < tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items.length; i++) {
                        finalCustomQty = parseInt(tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[i].quantity) + parseInt(finalCustomQty)
                        finalCustomAmount = (parseInt(tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[i].MRP) * parseInt(tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[i].quantity)) + parseInt(finalCustomAmount)
                }
                tempObj[draftIndex].TotalQuantity = finalCustomQty
                tempObj[draftIndex].Amount = finalCustomAmount
                tempObj[draftIndex].BalanceAmount = finalCustomAmount
                finalTotal = parseInt(finalCustomAmount)
                console.log(finalTotal)
                if (isNaN(customValue) || customValue < 1) {
                    tempCheckOutObj.splice(key, 1)
                    tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[key].quantity = tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items.splice(key,1)
                }
                tempItemList.map((v,k) => {
                    if (v.Sizes.length > 0) {
                        v.Sizes.map((v1,k1) => {
                            if (v1.ID === itemID) {
                                v1.SalesQty = parseInt(v1.SalesQty) + parseInt(itemValue.quantity)
                            }
                        })
                    } else {
                        if (v.ID === itemID) {
                            v.SalesQty = parseInt(v.SalesQty) + parseInt(itemValue.quantity)
                        }
                    }
                })
                itemsDataUpdate({items: tempItemList})
            } else if (actionType === 'bulkAdd') {
                // console.log(tempCheckOutObj, key, price, draftIndex)
                // tempObj[draftIndex].Amount = finalCustomAmount
                // tempObj[draftIndex].BalanceAmount = finalCustomAmount
            }
            // console.log(tempCheckOutObj[key].quantity)
            // console.log(finalTotal)
            updatePrice({
                subtotal : finalTotal,
                cgst : 0,
                sgst : 0})
            updateCart(tempCheckOutObj)
            cartDraftData({draftData: tempObj})
        } else if (stateName[1] === 'manageStock') {
            let tempCheckOutObj = JSON.parse(JSON.stringify(itemList))
            let finalTotal
            let tempObj = JSON.parse(JSON.stringify(draftData))
            let finalCustomQty = 0
            let finalCustomAmount = 0
            if (actionType === 'add') {
                tempCheckOutObj[key].quantity = tempCheckOutObj[key].quantity + 1
            } else if (actionType === 'minus') {
                if (tempCheckOutObj[key].quantity === 1) {
                    tempCheckOutObj.splice(key, 1)
                } else {
                    tempCheckOutObj[key].quantity = tempCheckOutObj[key].quantity - 1
                }
            } else if (actionType === 'custom') {
                tempCheckOutObj[key].quantity = parseInt(customValue)
                if (isNaN(customValue) || customValue < 1) {
                    tempCheckOutObj.splice(key, 1)
                }
            }
            updateStockPrice({
                subtotal : finalTotal,
                cgst : 0,
                sgst : 0})
            updateStock(tempCheckOutObj)
        }
    }
    // useEffect(() => {
    //     console.log(itemKey, draftIndex)
    //     if (itemKey !== -1) {
    //         console.log('IF')
    //         handleQty(itemKey, 'custom', 0)
    //     }
    // }, [itemKey])
    console.log(checkoutItemIndex)
    useEffect(() => {
        console.log(itemKey, checkoutItemIndex)
        if (itemKey !== null && itemKey !== '' && itemKey !== undefined) {
            if (itemKey !== -1) {
                if (stateName[1] === 'newOrder') {
                    console.log(itemValue)
                    if(itemValue === null || itemValue === undefined){
                        console.log('IF IF')
                        handleQty(checkoutItemIndex, 'bulkAdd', 0)
                    } else {
                        handleQty(itemKey, 'custom', 0, itemValue.item.ID)
                    }
                }
            }
            removeItem({itemKey: -1})
        }
    }, [itemKey, checkoutItemIndex, price])
    return (
        <ul class="listview image-listview flush no-line bg-transparent mt-0">
            {itemList?.map((value,index) => {
                return <>
                    <li class="p-1 pb-0 pt-0" style={{backgroundColor: (value?.OrderStatus === 1) ? '#34c75936' : ''}}>
                        <a href="javascript:void(0)" class="item p-0">
                            <div class="imageWrapper me-2">
                                {/* {(value.item.ImageURL === null || value.item.ImageURL === undefined || value.item.ImageURL === '') &&
                                    <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" alt="image" class="imaged" style={{height: '50px', width: '50px', objectFit: 'cover'}} />
                                }
                                {(value.item.ImageURL !== null && value.item.ImageURL !== undefined && value.item.ImageURL !== '') &&
                                    <img src={value.item.ImageURL} alt="image" class="imaged" style={{height: '50px', width: '50px', objectFit: 'cover'}} />
                                } */}
                                {value.item.FoodType === 'veg' &&
                                    <img src={Veg} width="22px" />
                                }
                                {value.item.FoodType === 'non-veg' &&
                                    <img src={NonVeg} width="22px" />
                                }
                            </div>
                            <div class="in">
                                <div>
                                    {(alternateTextVisible === 'englishText') &&
                                        <>
                                            {value.item.ItemTitle}
                                        </> 
                                    }
                                    {(alternateTextVisible === 'alternateText') &&
                                        <>
                                            {value.item.AlternateText}
                                        </>
                                    }
                                    {(alternateTextVisible === 'shortcode') &&
                                        <>
                                            {value.item.Shortcode}
                                        </>
                                    }
                                    <footer>₹{value.item.MRP} X{value.quantity} = ₹{value.item.MRP*value.quantity}</footer>
                                </div>
                                {value?.OrderStatus === 0 &&
                                    <span class="text-muted">
                                        <div class="stepper stepper-sm">
                                            <a href="javascript:void(0)" class="stepper-button stepper-down" onClick={() => {handleQty(index, 'minus', '', value.item.ID)}} style={{backgroundColor: (stateName[1] === 'manageStock') ? stockActionColor : '', borderColor: (stateName[1] === 'manageStock') ? stockActionColor : ''}}>-</a>
                                            <input type="text" class="form-control" min="0" value={value.quantity} onClick={() => {handleQuantity(index)}} style={{borderColor: (stateName[1] === 'manageStock') ? stockActionColor : ''}} />
                                            {/* onChange={(e) => {handleQty(index, 'custom', e.target.value, value.item.ID)}} */}
                                            <a href="javascript:void(0)" class="stepper-button stepper-up" onClick={() => {handleQty(index, 'add', '', value.item.ID)}} style={{backgroundColor: (stateName[1] === 'manageStock') ? stockActionColor : '', borderColor: (stateName[1] === 'manageStock') ? stockActionColor : ''}}>+</a>
                                        </div>
                                    </span>
                                }
                            </div>
                        </a>
                    </li>
                </>
            })} 
        </ul>
    )
}

const mapStateToProps = ({ general, checkoutItems, data, stock, quantity }) => {
    const {
        alternateTextVisible
    } =  general
    const {
        price
    } = checkoutItems
    const {
        draftData,
        draftIndex,
        odIndex,
        itemKey,
        itemValue,
        items
    } = data
    const {
        stockActionColor
    } = stock
    const {
        checkoutItemIndex
    } = quantity
    return {
        alternateTextVisible,
        price,
        draftData,
        draftIndex,
        odIndex,
        itemKey,
        stockActionColor,
        items,
        itemValue,
        checkoutItemIndex
    }
  }
  
const mapDispatchToProps = {
    showQuantityOffcanvas,
    updateCart,
    updatePrice,
    cartDraftData,
    removeItem,
    updateStock,
    updateStockPrice,
    itemsDataUpdate,
    showAppToast
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutItemList)
