import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component"
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import axios from '../../AxiosConfig'

const SalesReport = ({
    items
}) => {
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [summaryData, setSummaryData] = useState([])
    let navigate = useNavigate()

    useEffect(() => {
        let tempRowsData = []
        axios.get('/Reports/dashboardSummary').then(res => {
            if (res.data.message === 'Success') {
                setSummaryData(res.data.data)
                Object.keys(res.data.data.SalesReport).map((v,k) => {
                    tempRowsData.push({
                        id: k + 1,
                        date: v,
                        sale: res.data.data.SalesReport[v]
                    })
                })
                setRows(tempRowsData)
            } else {
                // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
            }
        })
    }, [])

    useEffect(() => {
        let tempColumnsData = [{
            id: 1,
            name: 'Date',
            selector: (row) => row.date,
            sortable: true,
            reorder: true
        },{
            id: 2,
            name: 'Sale',
            selector: (row) => row.sale,
            sortable: true,
            reorder: true
        }]
        setColumns(tempColumnsData)
        if (items === null || items === undefined || items === '') {
            navigate('/load')
        }
    }, [items])

    const paginationComponentOptions = {
      selectAllRowsItem: true,
      selectAllRowsItemText: "ALL"
    }
    return (
        <div id="appCapsule">
            <div class="section full mb-2 mt-2">
                <div class="wide-block p-0 border">
                    <DataTable
                        columns={columns}
                        data={rows}
                        defaultSortFieldId={1}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data }) => {
    const {
        items
    } = data
    return {
        items
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport)
