import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import Cropper from 'react-cropper'
import { hideAddSetupModal } from '../../../features/Offcanvas/addSetupModalSlice'
import { setTempSetupObj, setAllCategoriesStore, setAllItemsStore } from '../../../features/Data/dataSlice'
import { showImageEditModal } from '../../../features/Offcanvas/imageEditModalSlice'

const AddSetupModal = ({
    hideAddSetupModal,
    addSetupModalVisible,
    type,
    allCategories,
    allItems,
    setupData,
    actionType,
    tempSetupObj,
    setTempSetupObj,
    allCategoriesStore,
    setAllCategoriesStore,
    allItemsStore,
    arrKey,
    setAllItemsStore,
    showImageEditModal,
    tempSetupDataImage
}) => {
    console.log(type)
    const [categoryName, setCategoryName] = useState('')
    const [btnColor, setBtnColor] = useState('')
    const [iconType, setIconType] = useState('')
    const [itemName, setItemName] = useState('')
    const [itemCategoryName, setItemCategoryName] = useState('')
    const [itemMRP, setItemMRP] = useState('')
    const [itemCost, setItemCost] = useState('')
    const [selectImage, selectedImage] = useState([])
    const [cropper, setCropper] = useState()
    const croperef = useRef()
    useEffect(() => {
        setCategoryName(setupData?.Name)
        if (actionType === 'add') {
            setBtnColor('btn-success')
            setIconType('add-outline')
        } else if (actionType === 'rename') {
            setBtnColor('btn-secondary')
            setIconType('create-outline')
        } else if (actionType === 'remove') {
            setBtnColor('btn-danger')
            setIconType('trash-bin-outline')
        }
        if (setupData !== '' && setupData !== null && setupData !== undefined) {
            setItemName(setupData.Title)
            setItemCategoryName(setupData.CatgeoryName)
            setItemMRP(parseInt(setupData.MRP))
            setItemCost((parseInt(setupData.Cost)) ? setupData.Cost : 0)
        }
    }, [setupData, actionType])
    // useEffect(() => {
    //     if (tempSetupDataImage) {
    //         let setupDataImage = JSON.parse(JSON.stringify(setupData))
    //         setupDataImage.ImageURL = tempSetupDataImage.ImageURL
    //     }
    // }, [tempSetupDataImage])
    const submitCategory = (actionTyp) => {
        if (actionTyp === 'rename') {
            let updatedSetupObj = JSON.parse(JSON.stringify(tempSetupObj))
            let updatedCategoryName = JSON.parse(JSON.stringify(allCategoriesStore))
            updatedCategoryName.map((v,k) => {
                if (v.Name === setupData.Name) {
                    updatedCategoryName[k].Renamed = categoryName
                }   
            })
            setAllCategoriesStore({allCategoriesStore: updatedCategoryName})
            if (updatedSetupObj?.hasOwnProperty(setupData.Name)) {
                updatedSetupObj[setupData.Name].CategoryName = categoryName
            }
            setTempSetupObj({tempSetupObj: updatedSetupObj})
            hideAddSetupModal()
        } else {
            let updatedCategoryName = JSON.parse(JSON.stringify(allCategoriesStore))
            updatedCategoryName.push({
                "ID": null,
                "Name": categoryName,
                "count": false
            })
            setAllCategoriesStore({allCategoriesStore: updatedCategoryName})
            hideAddSetupModal()
        }
    }
    // const handleFileSelect = (e) => {
    //     const file = e.target.files[0];
    //     const fileTypes = ['image', 'image/png', 'image/jpeg', 'image/jpg']
    //     if (!fileTypes.includes(file.type)) {
    //         return
    //     }
    //     const fileUrl = URL.createObjectURL(e.target.files[0]);
    //     selectedImage(fileUrl);
    //     // setCropperActive(true);
    // }
    const updateItem = () => {
        let updatedItemData = JSON.parse(JSON.stringify(allItemsStore))
        console.log(updatedItemData)
        console.log(updatedItemData[arrKey])
        let tempUpdatedItemData = {
            "Title": "",
            "CatgeoryName":"",
            "MRP":"",
            "Cost":"",
            "ImageURL":""
        }
        if (updatedItemData[arrKey] === undefined || updatedItemData[arrKey] === null) {
            updatedItemData[arrKey] = tempUpdatedItemData
            updatedItemData[arrKey].Title = itemName
            updatedItemData[arrKey].CatgeoryName = itemCategoryName
            updatedItemData[arrKey].MRP = itemMRP
            updatedItemData[arrKey].Cost = itemCost
            updatedItemData[arrKey].ImageURL = (tempSetupDataImage.ImageURL) ? tempSetupDataImage.ImageURL : 'https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6'
            setAllItemsStore({allItemsStore: updatedItemData})
        } else {
            updatedItemData[arrKey].Title = itemName
            updatedItemData[arrKey].CatgeoryName = itemCategoryName
            updatedItemData[arrKey].MRP = itemMRP
            updatedItemData[arrKey].Cost = itemCost
            updatedItemData[arrKey].ImageURL = (tempSetupDataImage.ImageURL) ? tempSetupDataImage.ImageURL : setupData.ImageURL
            setAllItemsStore({allItemsStore: updatedItemData})
        }
        hideAddSetupModal()
    }
    const updateImage = (actionType) => {
        console.log(setupData)
        if (actionType === 'update') {
            if (setupData) {
                showImageEditModal({imageEditModalVisible: true, imageEditData: setupData})
            } else {
                showImageEditModal({imageEditModalVisible: true, imageEditData: null})
            }
        } else {
            let tempSetupData = {
                "Title": "",
                "CatgeoryName": "",
                "MRP": "",
                "Cost": "",
                "ImageURL": "https://webweb.ams3.cdn.digitaloceanspaces.com/data/DefaultImages/ItemImage.jpg"
            }
            showImageEditModal({imageEditModalVisible: true, imageEditData: tempSetupData})
        }
    }
    return (
        <div>
            <Modal show={addSetupModalVisible} backdrop='static' centered={true}>
                <Modal.Header closeButton style={{ padding: '10px 15px 0px 15px', borderBottom: 'none' }} onHide={() => {hideAddSetupModal()}}>
                    <div class="row w-100 position-relative">
                        {/* <div class="col-md-6 col-6 d-flex align-items-center">
                            <span style={{textTransform: 'capitalize'}}>{actionType}</span>&nbsp;{type}
                        </div> */}
                        {actionType !== 'add' &&
                            <div class="col-md-12 col-12 d-flex align-items-center justify-content-end">
                                <button class="btn btn-outline-danger btn-sm">
                                    <ion-icon name="trash-bin-outline" class="fs-6"></ion-icon> Remove {type}
                                </button>
                            </div>
                        }
                    </div>
                </Modal.Header>
                <Modal.Body class="pt-0 modal-body">
                    {type === 'Category' &&
                        <>
                            <div class="section mt-1 p-0">
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <input type="text" class="form-control" id="categoryName" value={(actionType === 'remove' || actionType === 'rename') ? categoryName : null} onChange={(e) => {setCategoryName(e.target.value)}} placeholder="Enter category name" autocomplete="off" disabled={(actionType === 'add' || actionType === 'rename') ? false : true} />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            {actionType !== 'remove' &&
                                <button class="btn btn-success btn-sm d-flex m-auto" onClick={() => {submitCategory(actionType)}}>
                                    <ion-icon name="checkmark-circle-outline" class="fs-6"></ion-icon> Submit
                                </button>
                            }
                        </>
                    }
                    {type === 'Item' &&
                        <div class="section mt-1 p-0">
                            <div class="row">
                                <div class="col-4 col-md-4">
                                    {(setupData !== '' && setupData !== null && setupData !== undefined) &&
                                        <>
                                            {(setupData.ImageURL !== '' && setupData.ImageURL !== null && setupData.ImageURL !== undefined) &&
                                            <div>
                                                <img src={(tempSetupDataImage?.ImageURL) ? tempSetupDataImage?.ImageURL : setupData?.ImageURL} style={{width: '100%', height: '120px', borderRadius: '6px'}} />
                                                <div class="d-flex m-auto justify-content-center align-items-center fs-10 text-primary cursor-pointer" onClick={() => {updateImage('update')}}>
                                                    <ion-icon name="create-outline" class="fs-10"></ion-icon>Change
                                                </div>
                                            </div>
                                            }
                                        </>
                                    }
                                    {(setupData === null) &&
                                        <>
                                            {(tempSetupDataImage?.ImageURL === '' || tempSetupDataImage?.ImageURL === null || tempSetupDataImage?.ImageURL === undefined) &&
                                                <div class="custom-file-upload" id="fileUpload1" style={{height: '120px'}} onClick={() => {updateImage('add')}}>
                                                    {/* <input type="file" id="fileuploadInput" accept="text/csv" /> */}
                                                    <label for="fileuploadInput">
                                                        <span>
                                                            <strong>
                                                                <ion-icon name="cloud-upload-outline"></ion-icon>
                                                                <i>Tap to Upload</i>
                                                            </strong>
                                                        </span>
                                                    </label>
                                                </div>
                                            }
                                            {tempSetupDataImage?.ImageURL &&
                                                <div>
                                                    <img src={tempSetupDataImage?.ImageURL} style={{width: '100%', height: '120px', borderRadius: '6px'}} />
                                                    {/* <div class="d-flex m-auto justify-content-center align-items-center fs-10 text-primary cursor-pointer" onClick={() => {updateImage('update')}}>
                                                        <ion-icon name="create-outline" class="fs-10"></ion-icon>Change
                                                    </div> */}
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                                <div class="col-8 col-md-8">
                                    <div class="row">
                                        <div class="col-6 col-md-6">
                                            <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="title">Item Name</label>
                                                    <input type="text" class="form-control" onChange={(e) => {setItemName(e.target.value)}} value={itemName} id="title" placeholder="Enter Title" style={{fontSize: '15px'}} autocomplete="off" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="itemCategory">Item Category</label>
                                                    <select class="form-control form-select" onChange={(e) => {setItemCategoryName(e.target.value)}} value={itemCategoryName} style={{fontSize: '15px'}} id="itemCategory">
                                                        <option value="">Select Category</option>
                                                        {allCategories.map((v,k) => {
                                                            return <option value={v.Name}>{v.Name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <div class="form-group">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="mrp">MRP</label>
                                                </div>
                                            </div>
                                            <div class="form-group searchbox">
                                                <input type="text" class="form-control" value={itemMRP} onChange={(e) => {setItemMRP(e.target.value)}} style={{height: '42px'}} />
                                                <i class="input-icon" style={{height: '42px', fontSize: '22px'}}>
                                                    {/* <ion-icon name="search-outline"></ion-icon> */}
                                                    ₹
                                                </i>
                                            </div>
                                            {/* <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <input type="number" min="0" onChange={(e) => {setItemMRP(e.target.value)}} value={itemMRP} class="form-control" id="mrp" placeholder="Enter MRP" autocomplete="off" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <div class="form-group">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="cost">Cost</label>
                                                </div>
                                            </div>
                                            <div class="form-group searchbox">
                                                <input type="text" class="form-control" onChange={(e) => {setItemCost(e.target.value)}} value={itemCost} style={{height: '42px'}} />
                                                <i class="input-icon" style={{height: '42px', fontSize: '22px'}}>
                                                    {/* <ion-icon name="search-outline"></ion-icon> */}
                                                    ₹
                                                </i>
                                            </div>
                                            {/* <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <input type="number" min="0" onChange={(e) => {setItemCost(e.target.value)}} value={itemCost} class="form-control" id="cost" placeholder="Enter Cost" autocomplete="off" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 mt-1">
                                    <button class="btn btn-success btn-sm d-flex m-auto" onClick={updateItem}>
                                        <ion-icon name="checkmark-circle-outline" class="fs-6"></ion-icon> Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ addSetup, data }) => {
    const {
        addSetupModalVisible,
        type,
        setupData,
        actionType,
        arrKey,
        tempSetupDataImage
    } = addSetup
    const {
        tempSetupObj,
        allCategoriesStore,
        allItemsStore
    } = data
    return {
        addSetupModalVisible,
        type,
        setupData,
        actionType,
        tempSetupObj,
        allCategoriesStore,
        arrKey,
        allItemsStore,
        tempSetupDataImage
    }
}

const mapDispatchToProps = {
    hideAddSetupModal,
    setTempSetupObj,
    setAllCategoriesStore,
    setAllItemsStore,
    showImageEditModal
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSetupModal)