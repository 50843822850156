import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { Grid } from '@splidejs/splide-extension-grid';
import { categoryIdUpdate, setCategoryName, setTempCategoryId } from '../../../features/Data/dataSlice'

const ItemCategories = ({
    categories,
    categoryIdUpdate,
    setCategoryName,
    categoryName,
    alternateTextVisible,
    colorVisible,
    setTempCategoryId,
    tempCategoryId,
    pageName
}) => {
    useEffect(() => {
        if (categoryName === '' || categoryName === undefined || categoryName === null) {
            categoryIdUpdate({categoryID: 'all'})
            setTempCategoryId({tempCategoryId: null})
        }
    }, [categoryName])
    const handleCategorySwitch = (categoryID, categoryName) => {
        categoryIdUpdate({categoryID: (categoryID === undefined || categoryID === null || categoryID === '') ? 1 : categoryID})
        setTempCategoryId({tempCategoryId: categoryID})
        setCategoryName({categoryName: categoryName})
    }
    function formatData(data, pageName) {
        const formattedData = [];
        if (pageName === 'AddRemoveStock') {
            for (let i = 0; i < data?.length; i ++) {
                const item1 = data[i];
                formattedData.push({
                    key: [
                        { ...item1}
                    ]
                });
            }
        } else {
            if (data.length > 5) {
                for (let i = 0; i < data?.length; i += 2) {
                    const item1 = data[i];
                    const item2 = data[i + 1];
                    if (item1 && item2) {
                      formattedData.push({
                        key: [
                          { ...item1},
                          { ...item2 }
                        ]
                      });
                    } else if (item1 && !item2) {
                        formattedData.push({
                            key: [
                              { ...item1}
                            ]
                          });
                    }
                  }
            } else {
                for (let i = 0; i < data?.length; i ++) {
                    const item1 = data[i];
                    if (item1) {
                      formattedData.push({
                        key: [
                          { ...item1}
                        ]
                      });
                    }
                }
            }
        }
        return formattedData;
    }
    const twoLineCategoryList = formatData(categories, pageName)
    return (
        <Splide
            options={{
                type: "slide",
                gap: 10,
                arrows: true,
                trimSpace: false,
                autoWidth: true,
                pagination: false,
                grid: {
                    rows: 2,
                    cols: 2,
                }
            }}
        >
            {(twoLineCategoryList !== null && twoLineCategoryList !== undefined) &&
                <>
                    {/* <SplideSlide>
                        <span class={`btn ${(ID === null || ID === 'all') ? 'border-secondary' : 'webweb-bg-secondary'} btn-sm`} style={{height: '40px'}} onClick={() => {handleCategorySwitch('all')}}>All</span>
                    </SplideSlide> */}
                    {twoLineCategoryList.map((value, key) => {
                        return <SplideSlide>
                            <div class="d-flex flex-column">
                                {value['key'].map((v1,k1) => {
                                    return <span class={`btn ${(tempCategoryId === v1.ID) ? 'border-secondary' : 'webweb-bg-secondary'} btn-sm mb-1`} style={{height: '40px', borderColor: (colorVisible) ? v1.Color : ''}} onClick={() => {handleCategorySwitch(v1.ID, v1.Name)}} key={k1}>
                                        {(alternateTextVisible === 'englishText' || alternateTextVisible === 'shortcode') &&
                                            <>
                                                {v1.Name && v1.Name}
                                            </>
                                        }
                                        {alternateTextVisible === 'alternateText' &&
                                            <>
                                                {v1.AlternateText}
                                            </>
                                        }
                                    </span>
                                })}
                            </div>
                        </SplideSlide>
                    })}
                </>
            }
        </Splide>
    )
}

const mapStateToProps = ({ data, general }) => {
    const {
        categories,
        categoryName,
        tempCategoryId
    } = data
    const {
        alternateTextVisible,
        colorVisible
    } = general
    return {
        categories,
        categoryName,
        alternateTextVisible,
        colorVisible,
        tempCategoryId
    }
  }
  
const mapDispatchToProps = {
    categoryIdUpdate,
    setCategoryName,
    setTempCategoryId
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemCategories)