import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import CheckoutComponent from './Components/CheckoutComponent'
import CheckoutOffcanvas from '../layout/Sidebar/CheckoutOffcanvas'
import NewOrderItem from './Components/NewOrderItem'
import ItemSizeOffcanvas from '../layout/Sidebar/ItemSizeOffcanvas'
import QuantityOffcanvas from '../layout/Sidebar/QuantityOffcanvas'
import { generalDataUpdate } from '../../features/utilities/generalSlice'
import { showQuickAddOffcanvas } from '../../features/Offcanvas/quickAddOffcanvasSlice'
import NotificationTop from '../layout/Sidebar/NotificationTop'

const NewOrder = ({
    generalDataUpdate,
    categories,
    items,
    checkoutItemList,
    topScrollValue,
    showQuickAddOffcanvas
}) => {
    const [documentResolution, setDocumentResolution] = useState(null)
    useEffect(() => {
        setDocumentResolution(window.screen.width)
    }, [documentResolution])
    let navigate = useNavigate()

    const itemList = []

    const buttonStyle = {
        "buttonText": "Place Order",
        "buttonColor":"success"
    }

    useEffect(() => {
        generalDataUpdate({buttonStyle: buttonStyle})
    }, [])

    useEffect(() => {
        if (categories === null || categories === undefined || items === null || items === undefined) {
            navigate('/load')
        }
    }, [categories, items])

    const handleQuickAdd =() => {
        showQuickAddOffcanvas({quickAddVisible: true})
    }
    return (
        <div id="appCapsule" class="pb-0">
            <div class="row">
                <div class="col-12 col-md-8 col-lg-9">
                    <NewOrderItem itemCardDetail={items} searchWithButton itemCategories itemView pageName={'NewOrder'} cardBg={''} categories={categories} countInRow={3}></NewOrderItem>
                </div>
                <div className="col-md-4 col-lg-3 d-none d-md-block border shadowed" style={{position: 'fixed', top: '56px', right: 0, bottom: 0}}>
                    <div className={(documentResolution !== null && documentResolution > 768) ? 'd-block' : 'd-none'}>
                        <CheckoutComponent topScroll={topScrollValue && topScrollValue} mobileNo itemList={itemList} checkoutButton={buttonStyle} pageName={'NewOrder'}></CheckoutComponent>
                    </div>
                    <CheckoutOffcanvas topScroll={topScrollValue && topScrollValue} mobileNo itemList={checkoutItemList} checkoutButton={buttonStyle} pageName={'NewOrder'}></CheckoutOffcanvas>
                    <ItemSizeOffcanvas></ItemSizeOffcanvas>
                    <QuantityOffcanvas></QuantityOffcanvas>
                    <div class="fab-button text bottom-center" onClick={handleQuickAdd}>
                        <a href="javascript:void(0)" class="fab fs-6 pe-2 ps-2" style={{height: '40px', fontFamily: "'Inter', sans-serif"}}>
                            <ion-icon name="flash-outline" class="fs-6"></ion-icon>
                            Quick Add
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data, checkoutItems }) => {
    const {
        categories,
        items,
        topScrollValue
    } = data
    const {
        checkoutItemList
    } = checkoutItems
    return {
        categories,
        items,
        checkoutItemList,
        topScrollValue
    }
  }
  
const mapDispatchToProps = {
    generalDataUpdate,
    showQuickAddOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(NewOrder)
