import React, { useState, useEffect } from 'react'
import axios from '../../AxiosConfig'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setConfigObj } from '../../features/Data/dataSlice'
import { showAppToast } from '../../features/utilities/appToastSlice'

const StoreConfig = ({
    configObj,
    setConfigObj,
    showAppToast
}) => {
    const [printKot, setPrintKot] = useState(false)
    const [printBill, setPrintBill] = useState(false)
    const [darkMode, setDarkMode] = useState(false)
    let navigate = useNavigate()

    useEffect(() => {
        if (configObj === null || configObj === undefined || configObj === '') {
            navigate('/load')
        } else {
            setDarkMode(configObj?.Config?.darkMode)
            if (configObj?.Config?.darkMode) {
                document.body.classList.add('dark-mode-active');
            } else {
                document.body.classList.remove('dark-mode-active');
            }
            setPrintKot(configObj?.Config?.printKot)
            setPrintBill(configObj?.Config?.printBillAuto)
        }
    }, [configObj])

    const handleSettings = (toggleValue, toggleType) => {
        if (toggleType === 'printKot') {
            setPrintKot(toggleValue)
        } else if (toggleType === 'printBillAUto') {
            setPrintBill(toggleValue)
        } else if (toggleType === 'darkMode') {
            setDarkMode(toggleValue)
            if (toggleValue) {
                document.body.classList.add('dark-mode-active');
            } else {
                document.body.classList.remove('dark-mode-active');
            }
        }
    }

    const submitSetting = () => {
        const data = {
                "Config":{
                'printKot': printKot,
                'printBillAuto': printBill,
                'darkMode': darkMode
            }
        }
        axios.post('/BusinessLocations', data).then(res => {
            if (res.data.message === 'Success') {
                setConfigObj({configObj: data})
                if (data?.Config.darkMode) {
                    setDarkMode(data?.Config.darkMode)
                    document.body.classList.add('dark-mode-active');
                } else {
                    setDarkMode(data?.Config.darkMode)
                    document.body.classList.remove('dark-mode-active');
                }
                showAppToast({ toastMessage: 'Success', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
            } else {
                showAppToast({ toastMessage: 'Error', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center', isVisible: true })
            }
        })
    }
    return (
        <div id="appCapsule">
            <div class="section full mt-1">
                <div class="row">
                    <div class="col-sm-6 mb-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <h3 class="mb-0 d-flex align-items-center">Print KOT</h3>
                                    <div>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="SwitchCheckDefault10" checked={printKot} value={printKot} onChange={() => {handleSettings(!printKot, 'printKot')}} />
                                            <label class="form-check-label" for="SwitchCheckDefault10"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <h3 class="mb-0 d-flex align-items-center">Print Bill (Automatic)</h3>
                                    <div>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="printBillAuto" checked={printBill} value={printBill} onChange={(e) => {handleSettings(!printBill, 'printBillAUto')}} />
                                            <label class="form-check-label" for="printBillAuto"></label>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <h3 class="mb-0 d-flex align-items-center">Dark Mode</h3>
                                    <div>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="darkMode" checked={darkMode} value={darkMode} onChange={(e) => {handleSettings(!darkMode, 'darkMode')}} />
                                            <label class="form-check-label" for="darkMode"></label>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="col-md-12 col-12 mb-2 d-flex justify-content-center">
                        <button class="btn btn-success" onClick={submitSetting}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data }) => {
    const {
        configObj
    } = data
    return {
        configObj
    }
  }
  
const mapDispatchToProps = {
    setConfigObj,
    showAppToast
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreConfig)
