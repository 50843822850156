import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import jwt_decode from 'jwt-decode'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from '../../AxiosConfig'
import moment from 'moment-timezone'
import CheckoutComponent from './Components/CheckoutComponent'
import NewOrderItem from './Components/NewOrderItem'
import { generalDataUpdate } from '../../features/utilities/generalSlice'
import CheckoutOffcanvas from '../layout/Sidebar/CheckoutOffcanvas'
import ItemSizeOffcanvas from '../layout/Sidebar/ItemSizeOffcanvas'

const AddRemoveStock = ({
    items,
    categories,
    topScrollValue,
    generalDataUpdate,
    checkoutItemList,
    authToken,
    stockItemList,
    stockActionType,
    categoryID,
    itemName
}) => {
    const [actionType, setActionType] = useState('')
    const [stockActionInput, showStockActionInput] = useState('vendor')
    const [BgClass, setbgClass] = useState('')
    const [columnsData, setColumnsData] = useState([])
    const [columns, setColumns] = useState([])
    const [filteredCategory, setFilteredCategory] = useState('')
    const [allCategoryFlag, setAllCategoryFlag] = useState(true)
    const itemList = []
    const stateName = window.location.pathname.split('/')
    const search = useLocation().search
    const action = new URLSearchParams(search).get('action')
    const targetTimezone = 'Asia/Kolkata'
    const newDate = moment().tz(targetTimezone).format('YYYY-MM-DD HH:mm:ss')
    const onlyDate = moment().tz(targetTimezone).format('YYYY-MM-DD')
    const tokenData = jwt_decode(authToken)
    useEffect(() => {
        setActionType(action)
    }, [search, stateName])
    const stockAction = new URLSearchParams(search).get('action')
    useEffect(() => {
        if (stateName[1] === 'manageStock') {
            if (stockAction === 'addStock') {
                setbgClass('text-warning')
            } else if (stockAction === 'removeStock') {
                setbgClass('text-danger')
            } else if (stockAction === 'transferOut') {
                setbgClass('text-pink')
            } else if (stockAction === 'request') {
                setbgClass('text-secondary')
            }
        } else {
            setbgClass('text-primary')
        }
    }, [search])
    let navigate = useNavigate()
    const buttonStyle = {
        "buttonText": "Place Order",
        "buttonColor":"success"
    }
    useEffect(() => {
        generalDataUpdate({buttonStyle: buttonStyle})
    }, [])
    useEffect(() => {
        if (categories === null || categories === undefined || categories === '') {
            navigate('/load')
        }
    }, [categories])
    const handleStock = (stockAction) => {
        showStockActionInput(stockAction)
    }
    useEffect(() => {
        if (items) {
            let filtered;
            if (categoryID !== 'all' && categoryID !== undefined) {
                setAllCategoryFlag(false)
                filtered = items.filter((item) => categoryID === item.Item_CategoryID);
            } else {
                setAllCategoryFlag(true)
                filtered = items
            }
            if(itemName?.trim().length > 0) filtered = filtered.filter((item) => (item?.ItemTitle?.toLowerCase()?.includes(itemName?.toLowerCase()?.trim()) || item?.AlternateText?.includes(itemName?.trim()) || item?.Shortcode?.includes(itemName?.trim())))
            setFilteredCategory(filtered)
        }
    }, [categoryID, itemName, items])
    useEffect(() => {
        let tempColumns = []
        let rowData = []
          if (items === null || items === undefined || items === '') {
              navigate('/load')
          } else {
            if (filteredCategory) {
                filteredCategory.map((v,k) => {
                    if (v.Stockable === 't') {
                        let tempRowData = {
                          id: v.ID,
                          itemName: v.ItemTitle,
                          total: 0
                        }
                        if (v.Sizes.length > 0) {
                            v.Sizes.map((v1,k1) => {
                              if (!tempColumns.includes(v1.SizeCategory)) {
                                tempColumns.push(v1.SizeCategory)
                              }
                              tempRowData[v1.SizeCategory] = v1.SalesQty 
                              tempRowData[v1.SizeCategory + 'ID'] = v1.ID 
                              tempRowData.total += parseInt(v1.SalesQty) 
                          })
                        }
                        rowData.push(tempRowData)
                    }
                })
                setColumns(tempColumns)
                setColumnsData(rowData)
            }
          }
      }, [items, filteredCategory])

    let stockObj = {    
        "Items": [],
        "TotalQty": 0,
        "Subtotal": 0,
        "TotalTax": 0,
        "Total": 0,
        "DateTime": null,
        "Currency": "INR",
        "Phone": "",
        "Date": null,
        "UserName": "",
        "Business_ID": tokenData.userInfo.Business_ID,
        "Location_ID": tokenData.userInfo.DefaultLocationID,
        "StockType": "",
        "Customer_ID": 0
    }
    const submitForm = () => {
        let tempBulkStockArr = []
        var form = document.querySelectorAll('#myForm input')
        var formData = {};
        for (var i = 0; i < form.length; i++) {
            var element = form[i];
            if (element.hasAttribute('data-id')) {
                var attributeName = element.getAttribute('data-id');
                var value = element.value;
                formData[attributeName] = value;
            }
            filteredCategory.map((v,k) => {
                if (v.Sizes.length > 0) {
                    v.Sizes.map((v1,k1) => {
                        if (v1.ID === attributeName) {
                            tempBulkStockArr.push({
                                "Item_ID": attributeName,
                                "Quantity": value
                            })
                        }
                    })
                } else {
                    if (v.ID === attributeName) {
                        tempBulkStockArr.push({
                            "Item_ID": attributeName,
                            "Quantity": value
                        })
                    }
                }
            })
        }
        let AddStockArr = []
        filteredCategory.map((v,k) => {
            tempBulkStockArr.map((v1, k1) => {
                if (v.Sizes.length > 0) {
                    v.Sizes.map((v2,k2) => {
                        if (v2.ID === v1.Item_ID) {
                            if (v1.Quantity > 0) {
                                AddStockArr.push({
                                    "quantity":v1.Quantity,
                                    "MRP":v2.MRP,
                                    "Cost":v2.Cost,
                                    "ItemTitle":v2.ItemTitle,
                                    "Item_ID":v2.ID,
                                    "Taxes":v2.Taxes,
                                    "Status":false,
                                    "Image": v2.ImageURL,
                                    "AlternateText": v2.AlternateText
                                })   
                            }
                        }
                    })
                } else {
                    if (v.ID === v1.Item_ID) {
                        if (v1.Quantity > 0) {
                            AddStockArr.push({
                                "quantity":v1.Quantity,
                                "MRP":v.MRP,
                                "Cost":v.Cost,
                                "ItemTitle":v.ItemTitle,
                                "Item_ID":v.ID,
                                "Taxes":v.Taxes,
                                "Status":false,
                                "Image": v.ImageURL,
                                "AlternateText": v.AlternateText
                            })   
                        }
                    }
                }
            })
        })
        stockObj.Items = AddStockArr
        stockObj.DateTime = newDate
        stockObj.Date = onlyDate
        stockObj.StockType = (stockActionType) ? stockActionType : 'addStock'
        axios.put('/Stock', stockObj).then(res => {
            if (res.data.message === 'Success') {
                // showAppToast({ toastMessage:'Success', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
            } else {
            //   showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000, isVisible: true})
            }
        })
    }
    return (
        <div id="appCapsule" class="pb-0">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-12">
                    <NewOrderItem itemCardDetail={items} searchWithButton itemCategories itemView pageName={'AddRemoveStock'} cardBg={''} categories={categories} countInRow={3}></NewOrderItem>
                </div>
                <div class="col-12 col-md-12 col-lg-12" style={{marginBottom: '60px'}}>
                    <div class="section full mt-4">
                        <div class="wide-block p-0">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead style={{borderBottom: '2px solid black'}}>
                                        <tr>
                                            <th scope="col">Item Title</th>
                                            <th scope="col">In Stock</th>
                                            <th scope="col">Add</th>
                                            {columns.map((v,k) => {
                                                return <th scope="col" class="text-end">{v}</th>
                                            })}
                                        </tr>
                                    </thead>
                                        {/* <form onSubmit={() => {submitForm()}}> */}
                                            <tbody id="myForm">
                                                {columnsData.map((v,k) => {
                                                    return <> 
                                                        <tr>
                                                            <td class="pb-0">
                                                                <div class="d-flex align-item-center">
                                                                    {v.itemName}
                                                                </div>
                                                            </td>
                                                            <td class="pb-0">
                                                                <div class="d-flex align-item-center">
                                                                    {v.total}
                                                                </div>
                                                            </td>
                                                            {!(columns.some((key) => v.hasOwnProperty(key))) ?
                                                                <td class="p-0">
                                                                    <div class="form-group boxed">
                                                                        <div class="input-wrapper">
                                                                            <input type="number" class="form-control form-control-sm ps-1 pe-1" placeholder='0' id="" min="0" style={{height: '30px', width: '70px'}} autocomplete="off" data-id={v.id} />
                                                                            <i class="clear-input">
                                                                                <ion-icon name="close-circle"></ion-icon>
                                                                            </i>
                                                                        </div>
                                                                    </div>
                                                                </td> 
                                                                :
                                                                <td class="pb-0 fw-bold">
                                                                    _
                                                                </td>
                                                            }
                                                            {columns.map((v1,k1) => {
                                                                return <>
                                                                {(columns.some((key) => v.hasOwnProperty(key))) ?
                                                                    <td class="p-0">
                                                                        <div class="form-group boxed">
                                                                            <div class="input-wrapper d-flex justify-content-end" style={{position: 'relative'}}>
                                                                                <input type="number" class="form-control form-control-sm ps-1 pe-1" id="" min="0" placeholder='0' style={{height: '30px', width: '70px'}} autocomplete="off" data-id={v[v1 + 'ID']} />
                                                                                <i class="clear-input">
                                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                                </i>
                                                                                <span class="badge bg-primary p-0" style={{height: '10px',width: '10px', position: 'absolute', fontSize: '10px', top: 0, right: 0}}>{v[v1]}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    :  
                                                                    <td class="pb-0 fw-bold text-end">
                                                                        _
                                                                    </td>
                                                                }
                                                                </>
                                                            })}
                                                        </tr>
                                                    </>
                                                })}
                                                <div class="fab-button text bottom-center responsive-bottom-fab">
                                                    <button class="fab fs-6 pe-2 ps-2" onClick={() => {submitForm()}} style={{height: '40px', fontFamily: "'Inter', sans-serif"}}>
                                                        <ion-icon name="add-outline" class="fs-6"></ion-icon>
                                                        Add Stock
                                                    </button>
                                                </div>
                                            </tbody>
                                        {/* </form> */}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ItemSizeOffcanvas></ItemSizeOffcanvas>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data, checkoutItems, auth, stock }) => {
    const {
        items,
        categories,
        topScrollValue,
        stockActionType,
        categoryID,
        itemName
    } = data
    const {
        checkoutItemList
    } = checkoutItems
    const {
        authToken
    } = auth
    const {
        stockItemList
    } = stock
    return {
        items,
        categories,
        topScrollValue,
        checkoutItemList,
        authToken,
        stockItemList,
        stockActionType,
        categoryID,
        itemName
    }
}
  
const mapDispatchToProps = {
    generalDataUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRemoveStock)
