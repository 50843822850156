import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'
import Logo from '../../images/logo.svg'
import ToastMessage from '../../Pages/Components/ToastMessage'
import axios from '../../../AxiosConfig'
import Moment from 'react-moment'
import printerLoader from '../../images/icon/printer.png'
import ConfirmationModal from '../../Elements/ConfirmationModal'
import AddTransactionModal from '../Sidebar/AddTransactionModal'
import MessageModal from '../Sidebar/messageModal'
import DineInTableOffcanvas from '../Sidebar/DineInTableOffcanvas'
import { useStopwatch } from 'react-timer-hook'
import { activeTabUpdatedName, setInvoiceID, setPaymentType, setPrintInvoice, setBusinessData, setTodaysSale, setKotData, itemsDataUpdate, setKeyCode } from '../../../features/Data/dataSlice'
import { showAddTransactionModal } from '../../../features/Offcanvas/addTransactionModalSlice'
import SwitchBusinessModal from '../Sidebar/SwitchBusinessModal'
import PaymentOffcanvas from '../Sidebar/PaymentOffcanvas'
import paidStamp from '../../images/icon/paid.png'
import unpaidStamp from '../../images/icon/unpaid.png'
import ConfirmationOffcanvas from '../Sidebar/ConfirmationOffcanvas'
import OngoingOffcanvas from '../Sidebar/OngoingOffcanvas'
import { showOngoingOffcanvas } from '../../../features/Offcanvas/ongoingOffcanvasSlice'
import ItemProfileOffcanvas from '../Sidebar/ItemProfileOffcanvas'
import AttendanceOffcanvas from '../Sidebar/AttendanceOffcanvas'
import { showAttendanceOffcanvas } from '../../../features/Offcanvas/confirmationModalSlice'
import ImageEditModal from '../Sidebar/ImageEditModal'
import DraftCartListModal from '../Sidebar/DraftCartListModal'
import DiscountModal from '../Sidebar/DiscountModal'
import ShortcutSearchModal from '../Sidebar/ShortcutSearchModal'
import { hideShortcutSearchModal, showShortcutSearchModal } from '../../../features/Offcanvas/shortcutSearchModalSlice'
import ShopStatusModal from '../Sidebar/ShopStatusModal'
import { showShopStatusModal } from '../../../features/Offcanvas/shopStatusModalSlice'
import OrderTypeModal from '../Sidebar/OrderTypeModal'
import AppToast from '../../Elements/Notification/AppToast'
import NotificationTop from '../Sidebar/NotificationTop'
import AddBalanceAmountModal from '../Sidebar/AddBalanceAmountModal'

function MyStopwatch() {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  return (
      // {/* <h1>react-timer-hook</h1>
      // <p>Stopwatch Demo</p> */}
      <div class="fs-10 d-flex fs-10">
        {days}:{hours}:{minutes}:{seconds}
      </div>
      // {/* <p>{isRunning ? 'Running' : 'Not running'}</p> */}
      // {/* <button onClick={start}>Start</button>
      // <button onClick={pause}>Pause</button>
      // <button onClick={reset}>Reset</button> */}
  )
}

const Header = ({
  setTodaysSale,
  todaysSale,
  activeTabUpdatedName,
  showAddTransactionModal,
  authToken,
  setInvoiceID, 
  setPaymentType,
  invoiceCount,
  printInvoice,
  setPrintInvoice,
  businessData,
  setBusinessData,
  invoiceWithOrders,
  kotData,
  setKotData,
  printFrom,
  paymentStatus,
  showOngoingOffcanvas,
  itemsDataUpdate,
  itemProfileVisible,
  showAttendanceOffcanvas,
  setKeyCode,
  tempKeyCode,
  showShortcutSearchModal,
  hideShortcutSearchModal,
  showShopStatusModal
}) => {
  const [headerTabName, setHeaderTabName] = useState('')
  const [invoiceLoader, setInvoiceLoader] = useState(false)
  const [kotOrderList, setKotOrderList] = useState(null)
  const [kotLoader, setKotLoader] = useState(false)
  const [branchData, setBranchData] = useState(null)
  const [businessTempData, setBusinessTempData] = useState(null)
  const [tempInvoiceOrderList, setTempInvoiceOrderList] = useState(null)
  const [invoiceSubtotal, setInvoiceSubtotal] = useState(0)
  const search = window.location.pathname.split('/')
  const queryString = window.location.search
  let navigate = useNavigate()
  useEffect(() => {
    if (search[1] === 'orders' || search[1] === 'manageStock') {
      localStorage.setItem('prevState', search[1] + queryString)
    } else {
      localStorage.setItem('prevState', search[1])
    }
    setHeaderTabName(search[1])
  }, [search])

  const handleUserProfile = () => {
    navigate('/userProfile')
  }

  useEffect(() => {
    axios.get('/Transactions/getTodaySale').then(res => {
      if (res.data.message === 'Success') {
        if (todaysSale !== null && todaysSale !== undefined) {
          if (todaysSale.Sale === res.data.data.Sale) {
          }
        } else if (todaysSale === null || todaysSale === undefined) {
          setTodaysSale({todaysSale : res.data.data})
        }
      } else {
      }
    })
  }, [])
  const prevBillNo = localStorage.getItem('billNo')
  useEffect(() => {
    if (prevBillNo === null || prevBillNo === undefined || prevBillNo === '') {
      navigate('/load')
    }
  }, [prevBillNo])

  const firstLogin = localStorage.getItem('firstLogin')
  useEffect(() => {
    if (firstLogin === null || firstLogin === undefined || firstLogin === '' || firstLogin === 'false') {
      showAttendanceOffcanvas({attendanceOffcanvasVisible: true})
    }
  }, [firstLogin])

  const handleAddPurchase = () => {
    activeTabUpdatedName({activeTabName: 'purchase'})
    navigate('/ordersDetail')
  }

  const handleAddWastage = () => {
    activeTabUpdatedName({activeTabName: 'wastage'})
    navigate('/ordersDetail')
  }

  const handleNewTransaction = () => {
    showAddTransactionModal({addTransactionModalVisible: true, transactionData: {
        "Transaction_Category_ID": 1,
        "Transaction_Type":	"expense",	
        "BalanceAmount": '100',	
        "Date":	new Date().toISOString().substr(0, 10),
        "Description": '',
        "Currency": "INR",	
        "PaymentMethod": 'cash',
        "Item_Order_ID": null,
        "OrderType": "",
        "ID": null
    }})
}

const handleNewOrderSwitch = () => {
  setInvoiceID({invoiceID: null})
  setPaymentType({balanceAmount: 0})
  navigate('/newOrder')
}

useEffect(() => {
  const tokenData = jwt_decode(authToken)
  if (businessData) {
    businessData.map((value, key) => {
      value.Branches.map((v,k) => {
          if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
              setBranchData(v)
              setBusinessTempData(value)
          }
      })
    }) 
  }
}, [businessData, authToken])

useEffect(() => {
  if (printInvoice === true) {
    setPrintInvoice({printInvoice: false})
    setInvoiceLoader(true)
    // if (invoiceWithOrders !== null && invoiceWithOrders !== undefined) {
    //   let tempOrdersList = []
    //   Object.keys(invoiceWithOrders.Orders).map((value, key) => {
    //     {JSON.parse(invoiceWithOrders.Orders[value].Items).map((k,v) => {
    //       tempOrdersList.push(k)
    //     })}
    //   })
    //   let sum = tempOrdersList.reduce(function(prev, current) {
    //     return prev + (+current.MRP*current.quantity)
    //   }, 0);
    //   setInvoiceSubtotal(sum)
    //   setTempInvoiceOrderList(tempOrdersList)
    // }
    setTimeout(() => {
        setPrintInvoice({printInvoice: false})
        setInvoiceLoader(false)
        var divToPrint = document.getElementById('printInvoiceReceipt');
        // var newWin = window.open('', 'Print-Window'); 
        var newWin = window.open('', '_blank'); 
        newWin.document.open();
        // newWin.document.write('<html><body onload="window.print(); window.close()">' + divToPrint.innerHTML + '</body></html>');
        newWin.document.write('<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Preview</title></head><body onload="window.print()">' + divToPrint.innerHTML + '</body><script>window.addEventListener("afterprint", function() {setTimeout(function() {window.close();}, 10000);});</script></html>');
        newWin.document.close();
    }, 3000);
  }
}, [printInvoice])

useEffect(() => {
  if (kotData !== null && kotData !== undefined) {
    setKotOrderList(kotData)
    setKotLoader(true)
    setTimeout(() => {
        setKotLoader(false)
        var divToPrint = document.getElementById('printKotReceipt');
        // var newWin = window.open('', 'Print-Window');
        var newWin = window.open('', '_blank');
        newWin.document.open();
        // newWin.document.write('<html><body onload="window.print(); window.close()">' + divToPrint.innerHTML + '</body></html>');
        newWin.document.write('<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Preview</title></head><body onload="window.print()">' + divToPrint.innerHTML + '</body><script>window.addEventListener("afterprint", function() {setTimeout(function() {window.close();}, 10000);});</script></html>');
        newWin.document.close();
        setKotData({kotData: null})
    }, 4000);
  }
}, [kotData])
const handleOrderSwitch = (ongoing) => {
    if (ongoing > 0 ) {
        showOngoingOffcanvas({ongoingOffcanvasVisible: true})
    } else if (ongoing === null || ongoing === undefined || ongoing === 0) {
        navigate('/orders?tab=sales&category=all')
    }
}
  
  // useEffect(() => {
  //   axios.get('/Items/business').then(res => {
  //     if (res.data.message === 'Success') {
  //       itemsDataUpdate({items: res.data.data})
  //     } else {
  //       // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
  //     }
  //   })
  // }, [itemProfileVisible])

  const handleKeyPress = (event) => {
      const keyCode = event.keyCode || event.which
      setKeyCode({tempKeyCode: keyCode})
  };

  useEffect(() => {
      // Attach the event listener when the component mounts
      document.addEventListener('keydown', handleKeyPress);

      // Remove the event listener when the component unmounts
      return () => {
          document.removeEventListener('keydown', handleKeyPress);
      };
  }, []);

  useEffect(() => {
    if (tempKeyCode === 111 || tempKeyCode === 191) {
      showShortcutSearchModal({shortcutSearchModalVisible: true})
    } else if (tempKeyCode === 27) {
      hideShortcutSearchModal()
    }
  }, [tempKeyCode])

  useEffect(() => {
    setInterval(() => {
      const prevShopStatus = localStorage.getItem('shopStatus')
      axios.get('/OpeningClosing/getShopStatus/' + new Date().toISOString().substr(0, 10)).then(res => {
        if (res.data.message === 'Success') {
          if (prevShopStatus !== null) {
            if (res.data.data !== prevShopStatus) {
              showShopStatusModal({shopStatusModalVisible: true, liveShopStatus: res.data.data})
              localStorage.setItem('shopStatus', res.data.data)
            }
          }
        } else {
            // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
        }
      })
    }, 30000);
  }, [])
  return (
    <div>
      <ToastMessage></ToastMessage>
      <ConfirmationModal></ConfirmationModal>
      <AddTransactionModal></AddTransactionModal>
      <MessageModal></MessageModal>
      <DineInTableOffcanvas></DineInTableOffcanvas>
      <SwitchBusinessModal></SwitchBusinessModal>
      <PaymentOffcanvas></PaymentOffcanvas>
      <ConfirmationOffcanvas></ConfirmationOffcanvas>
      <OngoingOffcanvas></OngoingOffcanvas>
      <ItemProfileOffcanvas></ItemProfileOffcanvas>
      {/* <AttendanceOffcanvas></AttendanceOffcanvas> */}
      <ImageEditModal></ImageEditModal>
      <DraftCartListModal></DraftCartListModal>
      <DiscountModal></DiscountModal>
      <ShortcutSearchModal></ShortcutSearchModal>
      {search[1] !== 'setup' &&
        <ShopStatusModal></ShopStatusModal>
      }
      <OrderTypeModal></OrderTypeModal>
      <AppToast></AppToast>
      <NotificationTop></NotificationTop>
      <AddBalanceAmountModal></AddBalanceAmountModal>
      <div className="appHeader">
        <div className="row w-100 d-flex align-items-center">
          <div className="w-25 d-flex justify-content-start">
            <a href="#!" className="headerButton">
              <img src={Logo} className="img-fluid" alt="Brand" width="69x69" />
            </a>
          </div>
          <div className="w-50 dropdown text-center">
            <div className="form-group boxed">
              <div className="input-wrapper d-flex  justify-content-center">
                <div class="d-none d-md-flex">
                  <div class="dropdown">
                    <Link to={'/summary'} class="me-1">
                      <div class={(headerTabName === 'summary') ? 'btn btn-sm shadowed rounded text-light' : 'btn btn-outline-primary btn-sm shadowed rounded'} style={{background: (headerTabName === 'summary') ? '#24265db8' : '', borderColor: (headerTabName === 'summary') ? '#24265db8' : ''}}>
                        <ion-icon name="storefront-outline"></ion-icon>
                        <span>Summary</span>
                      </div>
                    </Link>
                  </div>
                  <div class="dropdown">
                    <Link to={'/items'} class="me-1">
                      <div class={(headerTabName === 'items') ? 'btn btn-sm shadowed rounded text-light' : 'btn btn-outline-primary btn-sm shadowed rounded'} style={{background: (headerTabName === 'items') ? '#24265db8' : '', borderColor: (headerTabName === 'items') ? '#24265db8' : ''}}>
                          <ion-icon name="cube-outline"></ion-icon>
                          <span>Inventory</span>
                      </div>
                    </Link>
                  </div>
                  <div class="dropdown">
                    <a herf="javascript:void(0)" onClick={handleNewOrderSwitch} class="me-1 dropdown-toggle" data-bs-toggle="dropdown">
                      <div class={(headerTabName === 'newOrder') ? 'btn btn-sm shadowed rounded text-light' : 'btn btn-outline-primary btn-sm shadowed rounded'} style={{background: (headerTabName === 'newOrder') ? '#24265db8' : '', borderColor: (headerTabName === 'newOrder') ? '#24265db8' : ''}}>
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="text-nowrap">New Order</span>
                      </div>
                    </a>
                    {/* <div class="dropdown-menu mt-10" style={{marginTop: '10px'}}>
                        <a href="javascript:void(0)" class="dropdown-item pt-0 pb-0" onClick={handleNewOrderSwitch}>
                          <ion-icon name="add-circle-outline"></ion-icon>
                          New Order
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item pt-0" href="javascript:void(0)" onClick={handleNewTransaction}>
                          <ion-icon name="cash-outline"></ion-icon>
                          New Transaction
                        </a>
                        <a class="dropdown-item" href="javascript:void(0)" onClick={handleAddWastage}>
                          <ion-icon name="trash-outline"></ion-icon>
                          Add Wastage
                        </a>
                        <a class="dropdown-item pb-0" href="javascript:void(0)" onClick={handleAddPurchase}>
                          <ion-icon name="card-outline"></ion-icon>
                          Add Purchase
                        </a>
                    </div> */}
                  </div>
                  <div class="dropdown">
                    <a herf="javascript:void(0)" onClick={() => {handleOrderSwitch(invoiceCount)}} class="me-1">
                      <div class={(headerTabName === 'orders') ? 'btn btn-sm shadowed rounded text-light' : 'btn btn-outline-primary btn-sm shadowed rounded'} style={{background: (headerTabName === 'orders') ? '#24265db8' : '', borderColor: (headerTabName === 'orders') ? '#24265db8' : '', position: 'relative'}}>
                        {invoiceCount > 0 &&
                          <span class="badge bg-danger blink-ongoing-invoice" style={{position: 'absolute', top: -8, right: -5}}>{invoiceCount}</span>
                        }
                        <ion-icon name="cart-outline"></ion-icon>
                        <span>Orders</span>
                      </div>
                    </a>
                  </div>
                  <div class="dropdown">
                    <Link to={'/transactions?type=income&tcategory=all'} class="me-1">
                      <div class={(headerTabName === 'transactions') ? 'btn btn-sm shadowed rounded text-light' : 'btn btn-outline-primary btn-sm shadowed rounded'} style={{background: (headerTabName === 'transactions') ? '#24265db8' : '', borderColor: (headerTabName === 'transactions') ? '#24265db8' : ''}}>
                        <ion-icon name="swap-vertical"></ion-icon>
                        <span>Finance</span>
                      </div>
                    </Link>
                  </div>
                </div>
                <div class="d-block d-sm-none">
                    <h4 className="mb-0 d-flex justify-content-center align-items-center">
                      <span className='d-block d-lg-none my-auto pt-1 ms-1'></span>
                    </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="w-25 dropdown d-flex justify-content-end text-secondary fs-4 align-items-center">
            {/* {(invoiceCount !== null && invoiceCount !== undefined && invoiceCount > 0) &&
                <Link to={'/orders?tab=sales&category=all'} class="me-2 mb-1 position-relative d-block d-sm-none">
                  <div class="ongoing-active"></div>
                  <span class="badge badge-danger" style={{position: 'absolute', right: -6, top: -6}}>{invoiceCount}</span>
                  <span class="bg-success" style={{minWidth: '16px',height: '16px',lineHeight: '9px',fontSize: '12px',padding: '6px 12px', borderRadius: '4px'}}>Ongoing</span>                
                </Link>
              } */}
              <Link to="/summary">
                <h6 class="mb-0 fs-10 me-2 d-flex align-items-center justify-content-center text-center flex-column" style={{height: '24px'}}>
                    <small class="text-nowrap">Today's Sale</small>
                    <span class="fs-6 fw-bolder">₹{todaysSale && todaysSale.Sale}</span>
                </h6>
              </Link>
              <span className="dropdown position-relative">
                <span
                  className="headerButton d-flex"
                  data-bs-toggle="dropdown"
                  style={{ cursor: 'pointer' }}
                >
                  <div class="d-flex justify-content-center align-items-center" style={{height: '42px', width: '42px'}} onClick={handleUserProfile}>
                    <div class="rounded-circle webweb-border-secondary d-flex align-items-center justify-content-center" style={{boxShadow: '0px 0px 10px #34C759', height: '32px', width: '32px'}}>
                      <ion-icon name="person-outline" class="fs-5"></ion-icon>
                    </div>
                  </div>
                  <div style={{position: 'absolute', bottom: '-13px', left: '4px'}}>
                    {/* <MyStopwatch></MyStopwatch> */}
                  </div>
                </span>
              </span>
          </div>
        </div>
      </div>
      <div id="printInvoiceReceipt" style={{display: 'none'}}>
        <div onclick="window.close()">
            <font face="verdana" size="2">
                <div>
                    <div id="receiptDetails" style={{pageBreakAfter:'always'}}>
                        {/* <div style={{fontWeight:'bold',textAlign:'right',marginBottom:'10px'}}>Table 1</div>
                        <div style={{fontSize:'10px',textAlign:'center',marginBottom:'10px'}}>DUPLICATE</div> */}
                        <div style={{textAlign:'center',margin:'10px 0px 10px 0px'}}>
                          {(businessTempData?.Logo === null || businessTempData?.Logo === undefined) &&
                            <img height="60px" src="https://res.cloudinary.com/dc2toznmc/image/upload/v1573291347/file_oprzr9.jpg" />
                          }
                          {(businessTempData?.Logo !== null && businessTempData?.Logo !== undefined) &&
                            <img height="60px" src={businessTempData?.Logo} />
                          }
                        </div>
                        <div style={{fontWeight:'bold',textAlign:'center',wordWrap:'break-word'}}>{businessTempData?.Name}</div>
                        <div style={{textAlign:'center',wordWrap:'break-word'}}>{branchData?.Name}</div>
                        {/* <div style={{textAlign:'center',wordWrap:'break-word'}}>Bill no: {invoiceWithOrders?.OfflineInvoiceNumber}</div> */}
                        {/* <div style={{textAlign:'center',wordWrap:'break-word'}}>Invoice Number #{invoiceWithOrders?.OfflineInvoiceNumber}</div> */}
                        <div style={{textAlign:'center', fontSize: '12px'}}>Bill No - {invoiceWithOrders?.billNo}</div>
                        <div style={{textAlign:'center', fontSize: '12px'}}>{invoiceWithOrders?.OrderType}</div>
                        <div style={{textAlign:'center', fontSize: '12px'}}><Moment format="DD-MM-YYYY HH:mm">{invoiceWithOrders?.['Orders'][0].DateTime}</Moment></div>
                        <hr />
                        <table style={{width:'100%'}}>
                            <thead>
                                <tr>
                                    <th align="left" style={{fontSize: '12px'}}> ITEM </th>
                                    <th align="left" style={{fontSize: '12px'}}> QTY </th>
                                    <th align="left" style={{fontSize: '12px'}}> RATE </th>
                                    <th align="right" style={{fontSize: '12px'}}> PRICE </th>
                                </tr>
                            </thead>
                            <tbody>
                            {(invoiceWithOrders !== null && invoiceWithOrders !== undefined) &&
                              <>
                                {invoiceWithOrders?.itemArr?.map((value,key) => {
                                  return <tr>
                                      <td align="left" style={{fontSize: '10px'}}>{value.ItemTitle}</td>
                                      <td align="left" style={{fontSize: '10px'}}>{value.quantity}</td>
                                      <td align="left" style={{fontSize: '10px'}}>{value.MRP}</td>
                                      <td align="right" style={{fontSize: '10px'}}>{(parseInt(value.MRP)*value.quantity)}</td>
                                  </tr>
                                })}
                              </>
                            }
                            </tbody>
                        </table>
                        <hr />
                        <div style={{position: 'relative'}}>
                          <table style={{width:'100%'}}>
                              <tbody>
                                  <tr>
                                      <td align="left" style={{fontSize: '10px'}}>Subtotal </td>
                                      <td align="right" style={{fontSize: '10px'}}>{invoiceWithOrders?.Subtotal}</td>
                                  </tr>
                                  <tr>
                                      <td align="left" style={{fontSize: '10px'}}>Discount ({invoiceWithOrders?.DiscountType}) </td>
                                      <td align="right" style={{fontSize: '10px'}}>{invoiceWithOrders?.TotalDiscount}</td>
                                  </tr>
                                  <tr>
                                      <td align="left" style={{fontSize: '10px'}}>Tax (0%) </td>
                                      <td align="right" style={{fontSize: '10px'}}>0</td>
                                  </tr>
                                  <tr>
                                      <td align="left" style={{fontWeight:600, fontSize: '10px'}}>Grand Total</td>
                                      <td align="right" style={{fontWeight:600, fontSize: '10px'}}>{invoiceWithOrders?.GrandTotal}</td>
                                  </tr>
                              </tbody>
                          </table>
                          {paymentStatus === 'paid' &&
                            <img src={paidStamp} alt='paid' width="180px" style={{position: 'absolute', right: 0, bottom: '-80px'}} />
                          }
                          {/* {paymentStatus === 'unpaid' &&
                            <img src={unpaidStamp} alt='paid' width="180px" style={{position: 'absolute', right: 0, bottom: '-80px'}} />
                          } */}
                        </div>
                        <hr />
                        <div style={{textAlign:'center',marginTop:'15px',marginBottom:'15px',wordWrap:'break-word'}}>Thank You. Visit Again.</div>
                        <hr />
                        <div align="center" style={{fontSize:'10px'}}>
                            Powered by <b>allpos.com</b>
                        </div>
                        <br />
                    </div>
                </div>
            </font>
        </div>
      </div>
      {invoiceLoader &&
          <div id="loader" class="d-flex flex-column" style={{background: '#00000040'}}>
              <img src={printerLoader} alt="" width="75px" />
              <button class="btn bg-transparent fs-6 mt-2" type="button" disabled>
                  <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                  Printing Invoice...
              </button>
          </div>
      }
      <div id="printKotReceipt" style={{display: 'none'}}>
        <body onload="processPrint()" data-new-gr-c-s-check-loaded="14.1121.0" data-gr-ext-installed="">
            <font face="verdana" size="2">
                <div>
                    <div>
                        <div id="orderTicketDetails">
                            <div class="orderTicket" style={{display: 'block'}}>
                                <div style={{pageBreakAfter:'always'}}>
                                    {/* <div style={{fontSize:'10px',fontWeight:'bold',textAlign:'center'}}>#{kotOrderList?.OrderNumber}</div> */}
                                    {/* <div style={{fontSize:'8px',textAlign:'center', fontWeight: 'bold'}}>(Main Kitchen)</div> */}
                                    <div style={{fontSize:'12px',textAlign:'center', fontWeight: 'bold'}}>KOT - {kotOrderList?.kotNumber}</div>
                                    <div style={{fontSize:'12px',textAlign:'center', fontWeight: 'bold'}}> {kotOrderList?.Type}</div>
                                    {/* <div style={{fontSize:'12px',textAlign:'center', fontWeight: 'bold'}}>Bill No - {kotOrderList?.kotNumber}</div> */}
                                    {/* <div> */}
                                        {/* <small style={{float:'center', fontSize: '10px'}}>Time</small> */}
                                        <div style={{fontSize:'12px',textAlign:'center', fontWeight: 'bold'}}><Moment format="DD-MM-YYYY HH:mm">{kotOrderList?.DateTime}</Moment></div>
                                    {/* </div> */}
                                    {/* <br /> */}
                                    {/* <div>
                                        <small style={{float:'left', fontSize: '10px'}}>Created by</small>
                                        <small style={{float:'right', fontSize: '10px'}}>Saurabh Karve</small>
                                    </div> */}
                                    {/* <br /> */}
                                    <hr />
                                    {/* <div align="center" style={{fontSize:'10px',fontWeight:'bold',}}>ADDED ITEMS</div> */}
                                    <table style={{width:'100%'}}>
                                        <thead>
                                            <tr>
                                                <th align="left" style={{fontSize: '14px'}}> ITEM </th>
                                                <th align="right" style={{fontSize: '14px'}}> QTY </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(kotOrderList !== null && kotOrderList !== undefined) &&
                                                <>
                                                {printFrom === 'NewOrder' &&
                                                    <>
                                                      {kotOrderList?.Items?.map((v,k) => {
                                                          return <tr>
                                                              <td align="left" style={{fontSize: '12px'}}>{v?.ItemTitle}</td>
                                                              <td align="right" style={{fontSize: '12px'}}>+{v?.quantity}</td>
                                                          </tr>
                                                      })}
                                                    </>
                                                  }
                                                  {printFrom === 'OngoingOrder' &&
                                                    <>
                                                      {kotOrderList?.Items?.map((v,k) => {
                                                          return <tr>
                                                              <td align="left" style={{fontSize: '12px'}}>{v?.ItemTitle}</td>
                                                              <td align="right" style={{fontSize: '12px'}}>+{v?.quantity}</td>
                                                          </tr>
                                                      })}
                                                    </>
                                                  }
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                    <hr />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div id="serverOrderTicketDetails" style={{display: 'none'}}>
                            <div style={{fontSize:'10px',fontWeight:'bold',textAlign:'center',marginBottom:'10px'}}>SERVER COPY</div>
                            <div style={{marginTop:'10px'}}>
                                <span style={{float:'left'}}>SH-ZGP-2325-7</span>
                                <span style={{fontWeight:'bold',float:'right'}}>
                                    Table 1
                                </span>
                            </div>
                            <br />
                            <div>
                                <small style={{float:'left'}}>Time</small>
                                <small style={{float:'right'}}>Aug 26, 2023 07:17 PM</small>
                            </div>
                            <br />
                            <div>
                                <small style={{float:'left'}}>Created by</small>
                                <small style={{float:'right'}}>Saurabh Karve</small>
                            </div>
                            <br />
                            <div class="orderTicket">
                                <div style={{pageBreakAfter:'always'}}>
                                    <div style={{fontSize:'25px',fontWeight:'bold',textAlign:'center'}}>#4</div>
                                    <div style={{fontSize:'10px',textAlign:'center'}}>(Main Kitchen)</div>
                                    <hr />
                                    <div align="center" style={{fontSize:'10px',fontWeight:'bold'}}>ADDED ITEMS</div>
                                    <table style={{width:'100%'}}>
                                        <thead>
                                            <tr>
                                                <th align="left"> ITEM </th>
                                                <th align="right"> QTY </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td align="left">Burger</td>
                                                <td align="right">+1</td>
                                            </tr>
                                            <tr>
                                                <td align="left">Coffee</td>
                                                <td align="right">+4</td>
                                            </tr>
                                            <tr>
                                                <td align="left">Fries</td>
                                                <td align="right">+3</td>
                                            </tr>
                                            <tr>
                                                <td align="left">Snack</td>
                                                <td align="right">+2</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </font>
        </body>
      </div>
      {kotLoader &&
          <div id="loader" class="d-flex flex-column" style={{background: '#00000040'}}>
              <img src={printerLoader} alt="" width="75px" />
              <button class="btn bg-transparent fs-6 mt-2" type="button" disabled>
                  <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                  Printing KOT...
              </button>
          </div>
      }
    </div>
  )
}

const mapStateToProps = ({ data, auth, itemProfile }) => {
  const {
    todaysSale,
    invoiceCount,
    printInvoice,
    businessData,
    invoiceWithOrders,
    kotData,
    printFrom,
    paymentStatus,
    tempKeyCode
  } = data
  const {
    authToken
  } = auth
  const {
    itemProfileVisible
  } = itemProfile
  return {
    todaysSale,
    authToken,
    invoiceCount,
    printInvoice,
    businessData,
    invoiceWithOrders,
    kotData,
    printFrom,
    paymentStatus,
    itemProfileVisible,
    tempKeyCode
  }
}

const mapDispatchToProps = {
  setTodaysSale,
  activeTabUpdatedName,
  showAddTransactionModal,
  setInvoiceID,
  setPaymentType,
  setPrintInvoice,
  setBusinessData,
  setKotData,
  showOngoingOffcanvas,
  itemsDataUpdate,
  showAttendanceOffcanvas,
  setKeyCode,
  showShortcutSearchModal,
  hideShortcutSearchModal,
  showShopStatusModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header)
