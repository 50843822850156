import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    addStockOptionVisible: false,
    addItemOptionVisible: false
}

export const AddStockOptionOffcanvasSlice = createSlice({
    name: 'addStockOption',
    initialState,
    reducers: {
      showAddStockOptionOffcanvas: (state, action) => {
        const {
            addStockOptionVisible
        } = action.payload
        state.addStockOptionVisible = addStockOptionVisible
      },
      hideAddStockOptionOffcanvas: (state) => {
        state.addStockOptionVisible = false
      },
      showAddItemOptionOffcanvas: (state, action) => {
        const {
            addItemOptionVisible
        } = action.payload
        state.addItemOptionVisible = addItemOptionVisible
      },
      hideAddItemOptionOffcanvas: (state) => {
        state.addItemOptionVisible = false
      }
    }
})

export const { showAddStockOptionOffcanvas, hideAddStockOptionOffcanvas, showAddItemOptionOffcanvas, hideAddItemOptionOffcanvas } = AddStockOptionOffcanvasSlice.actions;


export default AddStockOptionOffcanvasSlice.reducer