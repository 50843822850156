import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { useLocation } from 'react-router-dom'
import CheckoutTopScroll from '../BitComponents/CheckoutTopScroll'
import CheckoutMobileNo from '../BitComponents/CheckoutMobileNo'
import CheckoutItemList from '../BitComponents/CheckoutItemList'
import CheckoutButton from '../BitComponents/CheckoutButton'
import { mobileCheckoutButtonData, setPhoneNumber, setPaymentType, checkoutItemType, cartDraftIndex, cartOrderIndex, cartDraftData, itemsDataUpdate } from '../../../features/Data/dataSlice'
import { updateCart, resetPrice, tempPrice } from '../../../features/state/checkoutItemsSlice'
import { showDiscountModal } from '../../../features/Offcanvas/discountModalSlice'
import { updateStock } from '../../../features/Data/stockSlice'

const CheckoutComponent = ({
    topScroll,
    mobileNo,
    checkoutItemList,
    checkoutButton,
    pageName,
    price,
    mobileCheckoutButtonData,
    updateCart,
    setPaymentType,
    setPhoneNumber,
    resetPrice,
    invoiceCount,
    fullInvoice,
    draftData,
    cartDraftID,
    checkoutItemType,
    cartDraftIndex,
    cartOrderIndex,
    tempPrice,
    draftIndex,
    odIndex,
    cartDraftData,
    showDiscountModal,
    type,
    discountType,
    discountedAmount,
    totalDiscount,
    stockItemList,
    updateStock,
    items,
    itemsDataUpdate
}) => {
    console.log(checkoutItemList)
    const [tempCartDraftData, setCartDraftData] = useState(null)
    const [orderActionInput, showOrderActionInput] = useState('customer')
    const [stockActionInput, showStockActionInput] = useState('vendor')
    const [stateName, setStateName] = useState('')
    const [BgClass, setbgClass] = useState('')
    const [stockQuantity, setStockQuantity] = useState(0)
    const [currentHeight, setCurrentHeight] = useState('inherit')
    const page = window.location.pathname.split('/')
    useEffect(() => {
        setStateName(page[1])
    }, [page])
    const search = useLocation().search
    const stockAction = new URLSearchParams(search).get('action')
    useEffect(() => {
        if (page[1] === 'manageStock') {
            if (stockAction === 'addStock') {
                setbgClass('text-warning')
            } else if (stockAction === 'removeStock') {
                setbgClass('text-danger')
            } else if (stockAction === 'transferOut') {
                setbgClass('text-pink')
            } else if (stockAction === 'request') {
                setbgClass('text-secondary')
            }
        } else {
            setbgClass('text-primary')
        }
    }, [search])
    let count = []
    let totalQty = 0
    useEffect(() => {
        if (checkoutItemList.length > 0) {
            checkoutItemList.map(value => {
                count.push(value.quantity)
                totalQty = count.reduce((a, b) => a + b, 0)
            })
            mobileCheckoutButtonData({totalQty: totalQty})
        }
    }, [checkoutItemList])

    useEffect(() => {
        let tempStockQuantity = 0
        if (stockItemList.length > 0) {
            for (let i = 0; i < stockItemList.length; i++) {
                tempStockQuantity = tempStockQuantity + stockItemList[i].quantity
            }
            setStockQuantity(tempStockQuantity)
        } else {
            setStockQuantity(0)
        }
    }, [stockItemList])

    const handleEmptyCart = (cartState) => {
        let tempItemList = JSON.parse(JSON.stringify(items))
        if (cartState === 'newOrder') {
            let tempObj = JSON.parse(JSON.stringify(draftData))
            if (draftIndex !== null && draftIndex !== undefined && draftIndex !== '') {
                tempObj[draftIndex].Amount = 0
                tempObj[draftIndex].BalanceAmount = 0
                tempObj[draftIndex].OrderType = ''
                tempObj[draftIndex].Status = -2
                tempObj[draftIndex].TotalQuantity = 0
                tempObj[draftIndex].Orders[odIndex].Items = []
            }
            cartDraftData({draftData: tempObj})

            
            let tempStockQty = {}
            checkoutItemList.map((v,k) => {
                tempStockQty[v.item.ID] = v.quantity
            })
            tempItemList.map((v,k) => {
                if (v.Sizes.length > 0) {
                    v.Sizes.map((v1,k1) => {
                        if (tempStockQty.hasOwnProperty(v1.ID)) {
                            v1.SalesQty = parseInt(v1.SalesQty) + tempStockQty[v1.ID]
                        }
                    })
                } else {
                    if (tempStockQty.hasOwnProperty(v.ID)) {
                        v.SalesQty = parseInt(v.SalesQty) + tempStockQty[v.ID]
                    }
                }
            })
            itemsDataUpdate({items: tempItemList})
            // ***************************************************
            updateCart([])
            setPaymentType({method: ''})
            setPhoneNumber({phone: null})
            resetPrice('')
            tempPrice({tempSubtotal: 0, tempBalance: 0})
        } else if (cartState === 'manageStock') {
            updateStock([])
        }
    }

    useEffect(() => {
        if (draftData) {
            draftData.map((v,k) => {
                if (cartDraftID === v.ID) {
                    setCartDraftData(v)
                    checkoutItemType({type: v.Title})
                }
            })
        }
    }, [draftData, cartDraftID])

    const handleDiscount = (currentPrice) => {
        showDiscountModal({discountModalVisible: true})
    }

    const handleOrder = (orderAction) => {
        showOrderActionInput(orderAction)
    }

    const handleStock = (stockAction) => {
        showStockActionInput(stockAction)
    }
    useEffect(() => {
        setCurrentHeight(document.getElementById('getHeight').clientHeight)
    }, [checkoutItemList])
    return (
        <div style={{overflow: 'auto'}}>
            {(topScroll || mobileNo) &&
                <>
                    {stateName === 'newOrder' &&
                        <div class="section full mt-0">
                            <div class="row">
                                <div class="appBottomMenu w-100 bg-transparent border-0" style={{position: 'relative'}}>
                                    <a href="javascript:void(0)" class={(orderActionInput === 'customer') ? "item active" : "item"} onClick={() => {handleOrder('customer')}}>
                                        <div class="col">
                                            <ion-icon name="person-outline" class="fs-5"></ion-icon>
                                            <strong>Customer</strong>
                                        </div>
                                    </a>
                                    {type === 'Dine In' &&
                                        <a href="javascript:void(0)" class={(orderActionInput === 'tableNo') ? "item active" : "item"} onClick={() => {handleOrder('tableNo')}}>
                                            <div class="col">
                                                <ion-icon name="filter-outline" class="fs-5"></ion-icon>
                                                <strong>Table No</strong>
                                            </div>
                                        </a>
                                    }
                                    <a href="javascript:void(0)" class={(orderActionInput === 'remark') ? "item active" : "item"} onClick={() => {handleOrder('remark')}}>
                                        <div class="col">
                                            <ion-icon name="book-outline" class="fs-5"></ion-icon>
                                            <strong>Remark</strong>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0)" class={(orderActionInput === 'assignTo') ? "item active" : "item"} onClick={() => {handleOrder('assignTo')}}>
                                        <div class="col">
                                            <ion-icon name="people-outline" class="fs-5"></ion-icon>
                                            <strong>Assign To</strong>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            {type === 'Dine In' &&
                                <>
                                    {orderActionInput === 'tableNo' &&
                                        <div class="row mb-1">
                                            <div class="col-12 col-md-12 pb-0">
                                                <div class="form-group boxed">
                                                    <div class="input-wrapper">
                                                        <div class="row">
                                                            {/* <div class="col-4 col-md-4 d-flex align-items-center">
                                                                <label class="form-label mb-0 text-nowrap me-2" for="tableNo">Enter Table No: </label>
                                                            </div> */}
                                                            <div class="col-12 col-md-12">
                                                                <input type="number" class="form-control form-control-sm ps-1 pe-1" id="tableNo" min="0" placeholder="Table No" autocomplete="off" style={{height: '36px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            {orderActionInput === 'customer' &&
                                <div class="row mb-1">
                                    {/* <div class="col-3 col-md-3">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper d-flex align-items-center">
                                                <label class="form-label mb-0 text-nowrap me-2" for="tableNo">Mobile No: </label>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="col-12 col-md-12 pt-1 pb-1">
                                        <CheckoutMobileNo></CheckoutMobileNo>
                                    </div>
                                </div>
                            }
                            {orderActionInput === 'remark' &&
                                <div class="row mb-1">
                                    <div class="col-12 col-md-12 pb-0">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <div class="row">
                                                    {/* <div class="col-2 col-md-2 d-flex align-items-center">
                                                        <label class="form-label mb-0 text-nowrap me-2" for="remark">Remark: </label>
                                                    </div> */}
                                                    <div class="col-12 col-md-12">
                                                        <div class="form-group searchbox">
                                                            <input type="text" class="form-control form-control-sm ps-1 pe-1" placeholder="Remark" style={{height: '36px'}} />
                                                            <i class="input-icon" style={{right: 0, left: 'auto'}}>
                                                                <ion-icon name="add-outline" class="fs-5"></ion-icon>
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {orderActionInput === 'assignTo' &&
                                <div class="row mb-1">
                                    <div class="col-12 col-md-12 pb-0">
                                        <div class="form-group boxed w-100">
                                            <div class="input-wrapper">
                                                <div class="row">
                                                    {/* <div class="col-4 col-md-4 d-flex align-items-center">
                                                        <label class="form-label mb-0 text-nowrap me-2" for="assignTo">Assign To: </label>
                                                    </div> */}
                                                    <div class="col-12 col-md-12">
                                                        <select class="form-control form-control-sm form-select form-select-sm ps-1 pe-1" id="assignTo" style={{height: '36px'}}>
                                                            <option value="user1">User 1</option>
                                                            <option value="user2">User 2</option>
                                                            <option value="user3">User 3</option>
                                                            <option value="user4">User 4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <form class="search-form">
                                {topScroll &&
                                    <div class="section full mb-4 mt-1 d-none">
                                        <CheckoutTopScroll topScrollValue={topScroll} pageName={pageName} shortCut={false}></CheckoutTopScroll>
                                    </div>
                                }
                            </form>
                        </div>
                    }
                    {stateName === 'manageStock' &&
                        <div class="section full mt-0">
                            <div class="row">
                                <div class="appBottomMenu w-100 bg-transparent border-0" style={{position: 'relative'}}>
                                    <a href="javascript:void(0)" class={(stockActionInput === 'vendor') ? "item active" : "item"} onClick={() => {handleStock('vendor')}}>
                                        <div class={`col ${BgClass}`}>
                                            <ion-icon name="person-outline" class="fs-5"></ion-icon>
                                            <strong>Vendor</strong>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0)" class={(stockActionInput === 'shop') ? "item active" : "item"} onClick={() => {handleStock('shop')}}>
                                        <div class={`col ${BgClass}`}>
                                            <ion-icon name="storefront-outline" class="fs-5"></ion-icon>
                                            <strong>Shop</strong>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0)" class={(stockActionInput === 'remark') ? "item active" : "item"} onClick={() => {handleStock('remark')}}>
                                        <div class={`col ${BgClass}`}>
                                            <ion-icon name="book-outline" class="fs-5"></ion-icon>
                                            <strong>Remark</strong>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0)" class={(stockActionInput === 'assignTo') ? "item active" : "item"} onClick={() => {handleStock('assignTo')}}>
                                        <div class={`col ${BgClass}`}>
                                            <ion-icon name="people-outline" class="fs-5"></ion-icon>
                                            <strong>Assign To</strong>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            {stockActionInput === 'vendor' &&
                                <div class="row mb-1">
                                    <div class="col-12 col-md-12 pb-0">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <div class="row">
                                                    <div class="col-12 col-md-12">
                                                        <input type="text" class="form-control form-control-sm ps-1 pe-1" id="vendor" min="0" placeholder="Vendor" autocomplete="off" style={{height: '36px'}} />
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {stockActionInput === 'shop' &&
                                <div class="row mb-1">
                                    <div class="col-12 col-md-12 pb-0">
                                        <div class="form-group boxed w-100">
                                            <div class="input-wrapper">
                                                <div class="row">
                                                    <div class="col-12 col-md-12">
                                                        <select class="form-control form-control-sm form-select form-select-sm ps-1 pe-1" id="shop" style={{height: '36px'}}>
                                                            <option value="shop1">Shop 1</option>
                                                            <option value="shop2">Shop 2</option>
                                                            <option value="shop3">Shop 3</option>
                                                            <option value="shop4">Shop 4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {stockActionInput === 'remark' &&
                                <div class="row mb-1">
                                    <div class="col-12 col-md-12 pb-0">
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <div class="row">
                                                    {/* <div class="col-2 col-md-2 d-flex align-items-center">
                                                        <label class="form-label mb-0 text-nowrap me-2" for="remark">Remark: </label>
                                                    </div> */}
                                                    <div class="col-12 col-md-12">
                                                        <div class="form-group searchbox">
                                                            <input type="text" class="form-control form-control-sm ps-1 pe-1" placeholder="Remark" style={{height: '36px'}} />
                                                            <i class="input-icon" style={{right: 0, left: 'auto'}}>
                                                                <ion-icon name="add-outline" class="fs-5"></ion-icon>
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {stockActionInput === 'assignTo' &&
                                <div class="row mb-1">
                                    <div class="col-12 col-md-12 pb-0">
                                        <div class="form-group boxed w-100">
                                            <div class="input-wrapper">
                                                <div class="row">
                                                    <div class="col-12 col-md-12">
                                                        <select class="form-control form-control-sm form-select form-select-sm ps-1 pe-1" id="assignTo" style={{height: '36px'}}>
                                                            <option value="user1">User 1</option>
                                                            <option value="user2">User 2</option>
                                                            <option value="user3">User 3</option>
                                                            <option value="user4">User 4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </>
            }  
            <div class="section full" style={{height: '61vh'}}>
                <div class="card border-0 bg-transparent shadow-none rounded-0" style={{height: 'inherit'}}>
                    <div class="card-body p-0" id="getHeight" style={{height: currentHeight, overflow: 'scroll'}}>
                        <p class="card-text">
                            {page[1] === 'newOrder' &&
                                <div class="p-0">
                                    {(checkoutItemList?.length > 0 && checkoutItemList !== null && checkoutItemList !== undefined) &&
                                        <CheckoutItemList itemList={checkoutItemList}></CheckoutItemList>
                                    }
                                    {(checkoutItemList?.length === 0 || checkoutItemList === null || checkoutItemList === undefined) &&
                                        <div class="d-flex justify-content-center align-items-center flex-column pt-4">
                                            <span class="fs-1 mb-2 mt-3">🛒</span>
                                            <span class="text-secondary">Your cart is empty.</span>
                                        </div>
                                    }
                                </div>
                            }
                            {page[1] === 'manageStock' &&
                                <div class="p-0">
                                    {(stockItemList?.length > 0 && stockItemList !== null && stockItemList !== undefined) &&
                                        <CheckoutItemList itemList={stockItemList}></CheckoutItemList>
                                    }
                                    {(stockItemList?.length === 0 || stockItemList === null || stockItemList === undefined) &&
                                        <div class="d-flex justify-content-center align-items-center flex-column pt-4">
                                            <span class="fs-1 mb-2 mt-3">🛒</span>
                                            <span class="text-secondary">Your cart is empty.</span>
                                        </div>
                                    }
                                </div>
                            }
                        </p>
                    </div>
                </div>
                {page[1] === 'newOrder' &&
                    <>
                        {(checkoutItemList.length > 0) &&
                            <>
                                {checkoutItemList[0].OrderStatus === 0 &&
                                    <div style={{position: 'absolute', bottom: '115px', right: '20px'}}>
                                        <button class="btn btn-sm btn-secondary rounded-circle p-0" style={{height: '35px', width: '35px'}} onClick={() => {handleEmptyCart('newOrder')}}>
                                            <ion-icon name="trash-bin-outline" class="me-0"></ion-icon>
                                        </button>
                                    </div>
                                }
                            </>
                        }
                    </>
                }
                {page[1] === 'manageStock' &&
                    <>
                        {(stockItemList.length > 0) &&
                            <div style={{position: 'absolute', bottom: '115px', right: '20px'}}>
                                <button class="btn btn-sm btn-secondary rounded-circle p-0" style={{height: '35px', width: '35px'}} onClick={() => {handleEmptyCart('manageStock')}}>
                                    <ion-icon name="trash-bin-outline" class="me-0"></ion-icon>
                                </button>
                            </div>
                        }
                    </>
                }
            </div>
            {pageName === 'NewOrder' &&
                <div class="section full mt-1" style={{position: 'absolute', bottom: '40px', right: 0, left: 0}}>
                    <div class="accordion" id="accordionExample1">
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed p-1 pt-0 pb-0" type="button" data-bs-toggle="collapse" data-bs-target="#accordion1" style={{minHeight: '30px', fontSize: '13px'}}>
                                    <div class="row w-100 pe-1">
                                        <div class="col-8 col-md-8 fw-bolder d-flex align-items-center">
                                            <div class="me-1">Subtotal</div>
                                            {/* <button class="badge badge-danger fs-10" style={{height: '16px'}} disabled>Cancel Orders</button> */}
                                        </div>
                                        <div class="col-4 col-md-4 fw-bolder text-end">
                                            ₹{price?.subtotal}
                                        </div>
                                        <div class="col-8 col-md-8 fw-bolder d-flex align-items-center">
                                            <div class="me-1 text-nowrap">Grand Total</div>
                                            {checkoutItemList.length > 0 &&
                                                <a href="javascript:void(0)" class="badge badge-primary fs-10" onClick={() => {handleDiscount(price)}} style={{height: '16px'}}>Add Discount</a>
                                            }
                                        </div>
                                        <div class="col-4 col-md-4 fw-bolder text-end">
                                            ₹{price?.subtotal+price?.cgst+price?.sgst - ((totalDiscount !== null && totalDiscount !== undefined && totalDiscount !== '') ? totalDiscount : 0)}
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="accordion1" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                <div class="accordion-body border-top p-0">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <tbody>
                                                {/* <tr>
                                                    <td class="p-1 border-0 border-0 pb-0">Subtotal</td>
                                                    <td class="p-1 border-0 border-0 pb-0 text-end">₹{price?.subtotal}</td>
                                                </tr> */}
                                                <tr>
                                                    <td class="p-1 border-0 border-0 pb-0">CGST</td>
                                                    <td class="p-1 border-0 border-0 pb-0 text-end">₹{price?.cgst}</td>
                                                </tr>
                                                <tr>
                                                    <td class="p-1 border-0 border-0 pb-0">SGST</td>
                                                    <td class="p-1 border-0 border-0 pb-0 text-end">₹{price?.sgst}</td>
                                                </tr>
                                                <tr>
                                                    <td class="p-1 border-0 border-0 pb-0">Discount</td>
                                                    <td class="p-1 border-0 border-0 pb-0 text-end">
                                                        ₹{totalDiscount}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {pageName === 'ManageStock' &&
                <div class="section full mt-1" style={{position: 'absolute', bottom: '40px', right: 0, left: 0}}>
                    <div class="accordion" id="accordionExample1">
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <div class="p-1 pt-0 pb-0" type="button" data-bs-toggle="collapse" data-bs-target="#accordion1" style={{minHeight: '30px', fontSize: '13px'}}>
                                    <div class="row">
                                        <div class="col-8 col-md-8 d-flex align-items-center">
                                            <div class="me-1">No. Of Items</div>
                                        </div>
                                        <div class="col-4 col-md-4 text-end">
                                            {stockItemList.length}
                                        </div>
                                        <div class="col-8 col-md-8 d-flex align-items-center">
                                            <div class="me-1 text-nowrap">Quantity</div>
                                        </div>
                                        <div class="col-4 col-md-4 text-end">
                                            {stockQuantity}
                                        </div>
                                    </div>
                                </div>
                            </h2>
                        </div>
                    </div>
                </div>
            }
            {checkoutButton &&
                <div class="section full" style={{position: 'absolute', bottom: 0, right: 0, left: 0}}>
                    <CheckoutButton buttonText={checkoutButton.buttonText && checkoutButton.buttonText} buttonColor={checkoutButton.buttonColor && checkoutButton.buttonColor} pageName={pageName}></CheckoutButton>
                </div>
            }
        </div>
    )
}

const mapStateToProps = ({ checkoutItems, data, stock }) => {
    const {
        checkoutItemList,
        price
    } = checkoutItems
    const {
        invoiceCount,
        fullInvoice,
        draftData,
        cartDraftID,
        draftIndex,
        odIndex,
        type,
        discountType,
        discountedAmount,
        totalDiscount,
        items
    } = data
    const {
        stockItemList
    } = stock
    return {
        checkoutItemList,
        price,
        invoiceCount,
        fullInvoice,
        draftData,
        cartDraftID,
        draftIndex,
        odIndex,
        type,
        discountType,
        discountedAmount,
        totalDiscount,
        stockItemList,
        items
    }
  }
  
const mapDispatchToProps = {
    mobileCheckoutButtonData,
    updateCart,
    setPaymentType,
    setPhoneNumber,
    resetPrice,
    checkoutItemType,
    cartDraftIndex,
    cartOrderIndex,
    tempPrice,
    cartDraftData,
    showDiscountModal,
    updateStock,
    itemsDataUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutComponent)
