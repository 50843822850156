import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hidePaymentOffcanvas } from '../../../features/Offcanvas/paymentOffcanvasSlice'
import Rupee from '../../images/icon/rupee.png'
import CreditCard from '../../images/icon/credit-card.png'
import OtherPaymentMethod from '../../images/icon/money-bag.png'
import Cheque from '../../images/icon/cheque.png'
import OnlinePayment from '../../images/icon/online-payment.png'
import UpiLogo from '../../images/icon/upi-logo.png'
import moment from 'moment-timezone'
import axios from '../../../AxiosConfig'
import { setPaymentType, setInvoiceID, setPrintInvoice, renderOngoingOrderList, cartDraftData, setInvoiceOrderList, setPhoneNumber, cartDraftIndex, cartOrderIndex, localStorageFlag, setDiscountDetails } from '../../../features/Data/dataSlice'
import { showToast } from '../../../features/Offcanvas/toastSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import { updateCart, resetPrice } from '../../../features/state/checkoutItemsSlice'
import PricingComponent from '../../Pages/BitComponents/PricingComponent'
import { showNotificationTopOffcanvas } from '../../../features/Offcanvas/notficationTopOffcanvasSlice'

const PaymentOffcanvas = ({
    paymentOffcanvasVisible,
    hidePaymentOffcanvas,
    price,
    setPaymentType,
    showToast,
    invoiceID,
    orderIDs,
    balanceAmount,
    setInvoiceID,
    setPrintInvoice,
    renderOngoingOrderList,
    offlineKotData,
    draftData,
    draftIndex,
    odIndex,
    cartDraftData,
    cartDraftID,
    setInvoiceOrderList,
    invoiceWithOrders,
    configObj,
    showAppToast,
    updateCart,
    resetPrice,
    setPhoneNumber,
    cartDraftIndex,
    cartOrderIndex,
    localStorageFlag,
    setDiscountDetails,
    fromState,
    showNotificationTopOffcanvas,
    itemSizeData,
    itemProfileData
}) => {
    console.log(itemSizeData)
    const [dynamicPrice, setDynamicPrice] = useState(null)
    const [totalBalanceAmount, updateBalanceAmount] = useState(0)
    const [validationMessage, setValidationMessage] = useState(false)

    const [sizeItemTitle, setSizeItemTitle] = useState('')
    const [sizeItemSize, setSizeItemSize] = useState('')
    const [addSizeInput, showAddSizeInput] = useState(false)

    const handlePaymentMethod = (paymentType) => {
        let defObj = {
            "OfflineInvoiceNumber": '',
            "Orders":[{
                "Items":[],
                "TotalQty":0,
                "Subtotal":0,
                "TotalTax":{
                    "cgst": 0,
                    "sgst":0
                },
                "Total":0,
                "Type":'',
                "DateTime":null,
                "OrderNumber":'',
                "OrderStatus": 0
            }],
            "Currency":"INR",
            "Phone":null,
            "Date": null,
            "UserName":"",
            "BalanceAmount":0,
            "PaymentMethod":"",
            "OrderType": '',
            "Amount": 0,
            "TransactionDate": null,
            "Customer_ID": "",
            "Status": -2
        }
        let OfflineInvoiceNumber = invoiceID
        let offlineOrderList = JSON.parse(localStorage.getItem('Orders'))
        let tempOfflineKotData = null
        if (offlineOrderList.length === 0) {
            // offlineOrderList.push(data)
            // const tempOfflineKotData = JSON.parse(JSON.stringify(offlineKotData))
            if (draftData[cartDraftID] !== null && draftData[cartDraftID] !== undefined) {
                tempOfflineKotData = JSON.parse(JSON.stringify((draftData[cartDraftID])))
            }
            tempOfflineKotData.PaymentMethod = paymentType
            tempOfflineKotData.BalanceAmount = (paymentType === 'Other') ? balanceAmount : totalBalanceAmount
            tempOfflineKotData.Status = 1
            tempOfflineKotData.TransactionDate = moment().tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss')
            tempOfflineKotData.Amount = balanceAmount
            tempOfflineKotData.Currency = "INR"
            localStorage.setItem('Orders', JSON.stringify([tempOfflineKotData]))
            renderOngoingOrderList({renderOngoingOrder: 'render'})
            setTimeout(() => {
                renderOngoingOrderList({renderOngoingOrder: ''})
            }, 3000);
        } else {
            let pushOfflineOrder = false
            offlineOrderList.map((value,key) => {
                if (value.OfflineInvoiceNumber === OfflineInvoiceNumber) {
                    offlineOrderList[key].PaymentMethod = paymentType
                    offlineOrderList[key].BalanceAmount = (paymentType === 'Other') ? balanceAmount : totalBalanceAmount
                    offlineOrderList[key].Status = 1
                    offlineOrderList[key].TransactionDate = moment().tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss')
                    offlineOrderList[key].Amount = balanceAmount
                    offlineOrderList[key].Currency = 'INR'
                } else {
                    pushOfflineOrder = true
                }
            })
            if (pushOfflineOrder) {
                if (draftData[cartDraftID] !== null && draftData[cartDraftID] !== undefined) {
                    tempOfflineKotData = JSON.parse(JSON.stringify((draftData[cartDraftID])))
                }
                tempOfflineKotData.PaymentMethod = paymentType
                tempOfflineKotData.BalanceAmount = (paymentType === 'Other') ? balanceAmount : totalBalanceAmount
                tempOfflineKotData.Status = 1
                tempOfflineKotData.TransactionDate = moment().tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss')
                tempOfflineKotData.Amount = balanceAmount
                tempOfflineKotData.Currency = "INR"
                offlineOrderList.push(tempOfflineKotData)
            }
            localStorage.setItem('Orders', JSON.stringify(offlineOrderList))
            // setTimeout(() => {
                //     renderOngoingOrderList({renderOngoingOrder: ''})
                // }, 3000);
                // renderOngoingOrderList({renderOngoingOrder: 'render'})
        }
        showNotificationTopOffcanvas({notificationTopOffcanvasVisible: true})
        hidePaymentOffcanvas({paymentOffcanvasVisible: false})
        // showAppToast({ toastMessage: 'Success', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
        
        // HEADER DRAFT BUTTON UPDATE
        let tempObj = JSON.parse(JSON.stringify(draftData))
        let itemArr = []
        tempObj[cartDraftID]?.Orders?.map((val,key) => {
            {val.Items?.map((v,k) => {
                itemArr.push(v)
            })}
        })
        tempObj[cartDraftID].itemArr = itemArr
        tempObj[cartDraftID].Subtotal = tempObj[cartDraftID].Amount
        tempObj[cartDraftID].GrandTotal = tempObj[cartDraftID].Amount
        if (configObj?.Config?.printBillAuto) {
            setPrintInvoice({printInvoice: true})
        }
        setInvoiceOrderList({invoiceWithOrders: tempObj[cartDraftID], paymentStatus: 'paid'})
        if (cartDraftID !== null && cartDraftID !== undefined && cartDraftID !== '') {
            tempObj[cartDraftID] = defObj
        }
        updateCart([])
        setPhoneNumber({phone: null})
        resetPrice('')
        cartDraftIndex({draftIndex: null})
        cartOrderIndex({odIndex : 0})
        localStorageFlag({localStorageUpdated: true})
        // setTimeout(() => {
            cartDraftData({draftData: tempObj})
        // }, 5200);
        setDiscountDetails({discountType: null, discountedAmount: 0, totalDiscount: 0, discountTypeString: ''})
    }
    let tempPrice = {}
    useEffect(() => {
        console.log(draftData)
        if ((balanceAmount !== null && balanceAmount !== undefined) ||  balanceAmount !== 0) {
            if (draftData !== null && draftData !== undefined && draftData !== '') {
                if (draftData[cartDraftID]?.DiscountTypeString === '' || draftData[cartDraftID]?.DiscountTypeString === null || draftData[cartDraftID]?.DiscountTypeString === undefined) {
                    setDynamicPrice({subtotal: (cartDraftID !== null) ? draftData[cartDraftID]?.Amount : 0, balanceAmount: (cartDraftID !== null) ? draftData[cartDraftID]?.BalanceAmount : 0})
                } else {
                    setDynamicPrice({subtotal: (cartDraftID !== null) ? draftData[cartDraftID]?.DiscountedAmount : 0, balanceAmount: (cartDraftID !== null) ? draftData[cartDraftID]?.DiscountedAmount : 0})
                }
            }
        } else {
            setDynamicPrice({subtotal: offlineKotData?.Subtotal})
        }
    }, [balanceAmount, offlineKotData])

    const setBalanceAmount = (updatedPrice) => {
        if (Number(updatedPrice) <= balanceAmount) {
            tempPrice = {
                'cgst': 0,
                'sgst': 0,
                'subtotal': (updatedPrice === '' || updatedPrice === NaN) ? 0 : parseInt(updatedPrice)
            }
            setDynamicPrice(tempPrice)
            updateBalanceAmount((draftData[cartDraftID]?.DiscountTypeString === '' || draftData[cartDraftID]?.DiscountTypeString === null || draftData[cartDraftID]?.DiscountTypeString === undefined) ? (draftData[cartDraftID]?.Amount - tempPrice.subtotal) : (draftData[cartDraftID]?.DiscountedAmount - tempPrice.subtotal))
            setValidationMessage(false)
        } else {
            setValidationMessage(true)
        }
    }
    const handlePrintInvoice = () => {
        let tempKotObj = JSON.parse(JSON.stringify(draftData[cartDraftID]))
        let itemArr = []
        tempKotObj.Orders?.map((val,key) => {
            {val.Items?.map((v,k) => {
                itemArr.push(v)
            })}
        })
        tempKotObj.Subtotal = tempKotObj.BalanceAmount
        tempKotObj.GrandTotal = tempKotObj.BalanceAmount
        tempKotObj.itemArr = itemArr
        hidePaymentOffcanvas()
        setPrintInvoice({printInvoice: true})
        setInvoiceOrderList({invoiceWithOrders: tempKotObj, paymentStatus: 'unpaid'})
    }

    useEffect(() => {
        if (itemSizeData) {
            setSizeItemTitle((itemSizeData) ? itemSizeData.ItemTitle : '')
            setSizeItemSize((itemSizeData) ? itemSizeData.SizeCategory : '')
        }
    }, [itemSizeData])
    const updateItemSize = (value) => {
        if (value !== 'Add Size') {
            showAddSizeInput(true)
        } else {
            setSizeItemSize(value)
        }
    }
    const handleSubmitSizeItemUpdate = () => {
        const updatedItemSizeProfile = {
            // 'Shortcode': shortCode,
            // 'Color': color,
            // 'AlternateText': alternateText,
            // 'QuickAdd': (quickAccess === true) ? 't' : 'f',
            // "Saleable": (saleable === true) ? 't' : 'f',
            // "Category": categoryName,
            "SizeCategory": sizeItemSize,
            "Title": sizeItemTitle
            // "MRP": (tempMRP) ? tempMRP : 0,
            // "Cost": (tempCost) ? tempCost : 0,
            // "TaxInclusive": "t",
            // "Taxes": [],
            // "Stockable": (stockable === true) ? 't' : 'f',
            // "AllowNegativeStock":(allowNegativeStock === true) ? 't' : 'f',
        }
        console.log(updatedItemSizeProfile)
    }    
    return (
        <div>
            <Modal show={paymentOffcanvasVisible} centered={true} onHide={() => {hidePaymentOffcanvas()}}>
                <Modal.Header closeButton style={{border: (fromState !== 'itemProfile') ? 'none' : ''}}>
                    {fromState !== 'itemProfile' &&
                        <div class="row w-100 position-relative">
                            <div class="col-md-7 col-6 pe-3">
                                <div class="search-form ms-1">
                                    <div class="form-group searchbox">
                                        <input type="number" class="form-control w-100" placeholder="Amount" value={dynamicPrice && dynamicPrice.subtotal} max={dynamicPrice && dynamicPrice.balanceAmount} onChange={(e) => {setBalanceAmount(e.target.value)}} />
                                        <i class="input-icon text-dark">
                                            ₹
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 col-6 d-flex align-items-center justify-content-end">
                                <button class="btn btn-sm btn-secondary" onClick={handlePrintInvoice}><ion-icon name="print-outline"></ion-icon> Invoice</button>
                            </div>
                            <div style={{position: 'absolute', bottom: '-25px', left: '5px'}}>
                                <span class="text-secondary fs-8">₹{(totalBalanceAmount === NaN) ? 0 : totalBalanceAmount} Balance Amount</span>
                            </div>
                                {validationMessage &&
                                    <small class="text-danger position-absolute" style={{top: '35px', left: '5px'}}>Not Allowed</small>
                                }
                        </div>
                    }
                    {fromState === 'itemProfile' &&
                        <div class="row w-100 position-relative">
                            <div class="col-md-7 col-6 pe-3">
                                {itemSizeData.ItemTitle}
                            </div>
                            <div class="col-md-5 col-6 d-flex align-items-center justify-content-end">
                                <button class="btn btn-sm btn-success" onClick={() => {handleSubmitSizeItemUpdate()}}><ion-icon name="checkmark-circle-outline"></ion-icon> Update</button>
                            </div>
                        </div>
                    }
                </Modal.Header>
                <Modal.Body class="pt-0 modal-body">
                    {fromState !== 'itemProfile' &&
                        <div class="section mt-1">
                            <div class="row">
                                <div class="col-4 col-md-4 mb-2 text-center">
                                    <div class="card border-secondary h-100" onClick={() => {handlePaymentMethod('Cash')}}>
                                        <div class="card-body p-1">
                                            <img src={Rupee} alt="" class="w-50" />
                                            <h4 class="mt-1 mb-0 fw-bolder">CASH</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4 mb-2 text-center">
                                    <div class="card border-secondary h-100" onClick={() => {handlePaymentMethod('Card')}}>
                                        <div class="card-body p-1">
                                            <img src={CreditCard} alt="" class="w-50" />
                                            <h4 class="mt-1 mb-0 fw-bolder">CARD</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4 mb-2 text-center">
                                    <div class="card border-secondary h-100" onClick={() => {handlePaymentMethod('UPI')}}>
                                        <div class="card-body p-1 d-flex justify-content-end align-items-center flex-column">
                                            <img src={UpiLogo} alt="" class="w-75 pb-1" />
                                            <h4 class="mt-1 mb-0 fw-bolder">UPI</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4 mb-2 text-center">
                                    <div class="card border-secondary h-100" onClick={() => {handlePaymentMethod('Cheque')}}>
                                        <div class="card-body p-1 d-flex justify-content-center align-items-center flex-column">
                                            <img src={Cheque} alt="" class="w-25" />
                                            <h4 class="mt-1 mb-0">CHEQUE</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4 mb-2 text-center">
                                    <div class="card border-secondary h-100" onClick={() => {handlePaymentMethod('Payment-Gateway')}}>
                                        <div class="card-body p-1 d-flex justify-content-center align-items-center flex-column">
                                            <img src={OnlinePayment} alt="" class="w-25" />
                                            <h4 class="mt-1 mb-0">PAYMENT GATEWAY</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4 mb-2 text-center">
                                    <div class="card border-secondary h-100" onClick={() => {handlePaymentMethod('Other')}}>
                                        <div class="card-body p-1 d-flex justify-content-center align-items-center flex-column">
                                            <img src={OtherPaymentMethod} alt="" class="w-25" />
                                            <h4 class="mt-1 mb-0">OTHER</h4>
                                        </div>
                                    </div>
                                </div>
                                <small class="text-danger">Note: Please select payment method</small>
                            </div>
                            {/* <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check btn-sm" name="btnradio" id="btnradio1" checked />
                                <label class="btn btn-outline-primary" for="btnradio1">Apple</label>

                                <input type="radio" class="btn-check btn-lg" name="btnradio" id="btnradio2" />
                                <label class="btn btn-outline-primary" for="btnradio2">Banana</label>

                                <input type="radio" class="btn-check btn-lg" name="btnradio" id="btnradio3" />
                                <label class="btn btn-outline-primary" for="btnradio3">Orange</label>
                            </div> */}
                        </div>
                    }
                    {fromState === 'itemProfile' &&
                        <>
                            <div class="row">
                                <div class="col-6 col-md-6">
                                    <div class="form-group boxed">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="sizeItemTitle">Item Title</label>
                                            <input type="text" class="form-control" value={sizeItemTitle} onChange={(e) => {setSizeItemTitle(e.target.value)}} id="sizeItemTitle" placeholder="Item Title" autocomplete="off" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6">
                                    {addSizeInput &&
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="itemSize">Add Size</label>
                                                <input type="text" class="form-control" id="itemSize" placeholder="Item Size" autocomplete="off" />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                                <label class="form-label pt-1 d-flex align-items-center cursor-pointer" onClick={() => showAddSizeInput(false)}><ion-icon name="chevron-back-outline"></ion-icon>Back</label>
                                            </div>
                                        </div>
                                    }
                                    {!addSizeInput &&
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" for="sizeId">Select Size ID</label>
                                                <select class="form-control form-select" id="sizeId" value={sizeItemSize} onChange={(e) => updateItemSize(e.target.value)}>
                                                    {itemProfileData?.Sizes.map((v,k) => {
                                                        return <option value={v.SizeCategory}>{v.SizeCategory}</option>
                                                    })}
                                                    <option value="add">Add Size</option>
                                                </select>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <PricingComponent></PricingComponent>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ payment, checkoutItems, data, itemProfile }) => {
    const {
        paymentOffcanvasVisible,
        fromState,
        itemSizeData
    } = payment
    const {
        price
    } = checkoutItems
    const {
        invoiceID,
        orderIDs,
        balanceAmount,
        offlineKotData,
        draftIndex,
        draftData,
        odIndex,
        cartDraftID,
        invoiceWithOrders,
        configObj
    } = data
    const {
        itemProfileData
    } = itemProfile
    return {
        paymentOffcanvasVisible,
        price,
        invoiceID,
        orderIDs,
        balanceAmount,
        offlineKotData,
        draftIndex,
        draftData,
        odIndex,
        cartDraftID,
        invoiceWithOrders,
        configObj,
        fromState,
        itemSizeData,
        itemProfileData
    }
  }
  
  const mapDispatchToProps = {
    hidePaymentOffcanvas,
    setPaymentType,
    showToast,
    setInvoiceID,
    setPrintInvoice,
    renderOngoingOrderList,
    cartDraftData,
    setInvoiceOrderList,
    showAppToast,
    updateCart,
    resetPrice,
    setPhoneNumber,
    cartDraftIndex,
    cartOrderIndex,
    localStorageFlag,
    setDiscountDetails,
    showNotificationTopOffcanvas
  }

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOffcanvas)