import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import jwt_decode from 'jwt-decode'
import { hideCheckoutOffcanvas } from '../../../features/Offcanvas/checkoutOffcanvasSlice'
import { showPaymentOffcanvas } from '../../../features/Offcanvas/paymentOffcanvasSlice'
import { localStorageFlag, setPaymentType, setPhoneNumber, checkoutItemType, setOfflineKotData, setFullInvoice, cartDraftData, setCartDraftID, setCheckoutFlagDraft, setRenderHeaderDraft, cartDraftIndex, cartOrderIndex, setInvoiceID, setKotData, setDiscountDetails, itemsDataUpdate } from '../../../features/Data/dataSlice'
import { showToast } from '../../../features/Offcanvas/toastSlice'
import axios from '../../../AxiosConfig'
import { updateCart, resetPrice, tempPrice } from '../../../features/state/checkoutItemsSlice'
import { showConfirmActionOffcanvas } from '../../../features/Offcanvas/confirmationModalSlice'
import moment from 'moment-timezone'
import { showShopStatusModal } from '../../../features/Offcanvas/shopStatusModalSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import { updateStockQuantity, updateStock, updateStockPrice } from '../../../features/Data/stockSlice'

const CheckoutButton = ({
    showPaymentOffcanvas,
    hideCheckoutOffcanvas,
    buttonText,
    buttonColor,
    checkoutItemList,
    price,
    type,
    phone,
    pageName,
    activeTabName,
    localStorageFlag,
    triggerCheckoutFunction,
    method,
    showToast,
    setPaymentType,
    userData,
    updateCart,
    setPhoneNumber,
    mobileNumberExist,
    resetPrice,
    balanceAmount,
    checkoutItemType,
    topScrollValue,
    invoiceID,
    invoiceNumber,
    showConfirmActionOffcanvas,
    setOfflineKotData,
    fullInvoice,
    setFullInvoice,
    draftData,
    cartDraftID,
    cartDraftData,
    setCartDraftID,
    checkoutFlagDraft,
    setCheckoutFlagDraft,
    setRenderHeaderDraft,
    tempSubtotal,
    tempBalance,
    tempPrice,
    draftIndex,
    odIndex,
    cartDraftIndex, 
    cartOrderIndex,
    offlineKotData,
    setInvoiceID,
    setKotData,
    configObj,
    showShopStatusModal,
    discountType,
    discountedAmount,
    totalDiscount,
    setDiscountDetails,
    discountTypeString,
    stockActionColor, 
    stockActionType,
    stockItemList,
    showAppToast,
    authToken,
    items,
    itemsDataUpdate,
    updateStockQuantity, 
    updateStock, 
    updateStockPrice,
    checkoutItemIndex
}) => {
    const targetTimezone = 'Asia/Kolkata'
    const newDate = moment().tz(targetTimezone).format('YYYY-MM-DD HH:mm:ss')
    const onlyDate = moment().tz(targetTimezone).format('YYYY-MM-DD')
    const [disableButton, setDisableButton] = useState(true)
    const [checkoutType, setCheckoutType] = useState('')
    const [totalQty, setTotalQty] = useState(0)
    const [newCustomerID, setNewCustomerID] = useState(null)
    const [orderNumber, setOrderNumber] = useState(null)
    const [stateName, setStateName] = useState('')
    const [disableProceedButton, setDisableProceedButton] = useState(true)
    const page = window.location.pathname.split('/')
    const tokenData = jwt_decode(authToken)
    useEffect(() => {
        setStateName(page[1])
    }, [page])
    let count = 0
    useEffect(() => {
        setCheckoutType(type)
    }, [type])
    useEffect(() => {
        if (checkoutItemList.length > 0) {
            checkoutItemList.map(value => {
                if (value.OrderStatus === 0) {
                    count = count + value.quantity
                }
            })
            setTotalQty(count)
            if (checkoutItemList.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
            }   
        } else {
            setDisableButton(true)
        }
    }, [checkoutItemList])
    let tempCheckoutObj = {}
    let checkoutCartData = []
    let exsistingOrders = []

    
    const calculateYear = (number) => {
        let num = number.toString()
        if (num.length === 1) {
            num = '00' + num
        } else if (num.length === 2) {
            num = '0' + num
        }
        return num
    }

    const GetOrderdate = () => {
        const currentDate = new Date()
        const hour = currentDate.getHours();
        if (hour < 3) {
            const previousDay = new Date(currentDate.setDate(currentDate.getDate()));
            return previousDay.toISOString().split('T')[0];
        } else {
            return currentDate.toISOString().split('T')[0];
        }
    }
    
    useEffect(() => {
        console.log(checkoutItemList)
        // const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        let d = new Date();
        const year = d.getFullYear();
        const start = new Date(d.getFullYear(), 0, 0);
        const diff = d - start;
        const oneDay = 1000 * 60 * 60 * 24;
        const day = Math.floor(diff / oneDay);
        var seconds = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds();
        var minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        var hour = (d.getHours() < 10 ? '0' : '') + d.getHours();
        setOrderNumber(year + calculateYear(day) + 'AA' + 'K01' + hour + minutes + seconds)
    }, [checkoutItemList, draftIndex])
    let existingKotNumber = localStorage.getItem('kotNumber')
    let existingBillNo = localStorage.getItem('billNo')
    const handlePaymentMethod = (checkoutDraftType, paymetMessage) => {
        console.log(checkoutItemList)
        const currentShopStatus = localStorage.getItem('shopStatus')
        if (currentShopStatus === 'Close') {
            showShopStatusModal({shopStatusModalVisible: true, liveShopStatus: ''})
        } else {
            if ((userData === null || userData.ID === null || userData.ID === '' || userData.ID === undefined) && mobileNumberExist === true) {
                axios.put('/Customers', userData).then(res => {
                    if (res.data.message === 'Success') {
                        if (res.data.data !== null && res.data.data !== undefined) {
                            setNewCustomerID(res.data.data)
                            if (pageName === 'NewOrder') {
                                exsistingOrders = JSON.parse(localStorage.getItem('Orders'))
                            }else if (pageName === 'purchase') {
                                exsistingOrders = JSON.parse(localStorage.getItem('Purchase'))
                            } else if (pageName === 'wastage') {
                                exsistingOrders = JSON.parse(localStorage.getItem('Wastage'))
                            }
                            checkoutItemList.map(value => {
                                if (value.OrderStatus === 0) {
                                    checkoutCartData.push({
                                        "quantity":value.quantity,
                                        "MRP":value.item.MRP,
                                        "Cost":value.item.Cost,
                                        "ItemTitle":value.item.ItemTitle,
                                        "Item_ID":value.item.ID,
                                        "Taxes":value.item.Taxes,
                                        "Status":false,
                                        "Image": value.item.ImageURL,
                                        "AlternateText": value.item.AlternateText
                                    })   
                                }
                            })
                            tempCheckoutObj = {
                                "OfflineInvoiceNumber": "IN" + orderNumber,
                                "Orders":[{
                                    "Items":checkoutCartData,
                                    "TotalQty":totalQty,
                                    "Subtotal":price.subtotal,
                                    "TotalTax":{
                                        "cgst": price.cgst,
                                        "sgst":price.sgst
                                    },
                                    "Total":price.subtotal + price.sgst + price.cgst,
                                    "Type":checkoutType,
                                    "DateTime":newDate,
                                    "OrderNumber":orderNumber
                                }],
                                "Currency":"INR",
                                "Phone":(phone) ? phone : "",
                                "Date": onlyDate,
                                "UserName":"Hussain Kothari",
                                "BalanceAmount":price.subtotal + price.sgst + price.cgst - discountedAmount,
                                "PaymentMethod":"",
                                "OrderType": checkoutType,
                                "Amount": price.subtotal + price.sgst + price.cgst - discountedAmount,
                                "TransactionDate": newDate,
                                "Customer_ID": "",
                                "Status": 0,
                                "TotalDiscount": 0
                            }
                            console.log(tempCheckoutObj)
                            if (pageName === 'purchase') {
                                if (exsistingOrders === null || exsistingOrders === undefined) {
                                    localStorage.setItem('Purchase', JSON.stringify([tempCheckoutObj]))
                                    hideCheckoutOffcanvas()
                                    localStorageFlag({localStorageUpdated: true})
                                    updateCart([])
                                } else {
                                    exsistingOrders.push(tempCheckoutObj)
                                    hideCheckoutOffcanvas()
                                    localStorageFlag({localStorageUpdated: true})
                                    localStorage.setItem('Purchase', JSON.stringify(exsistingOrders))
                                    updateCart([])
                                }
                            } else if (pageName === 'wastage') {
                                if (exsistingOrders === null || exsistingOrders === undefined) {
                                    localStorage.setItem('Wastage', JSON.stringify([tempCheckoutObj]))
                                    hideCheckoutOffcanvas()
                                    localStorageFlag({localStorageUpdated: true})
                                    updateCart([])
                                } else {
                                    exsistingOrders.push(tempCheckoutObj)
                                    hideCheckoutOffcanvas()
                                    localStorageFlag({localStorageUpdated: true})
                                    localStorage.setItem('Wastage', JSON.stringify(exsistingOrders))
                                    updateCart([])
                                }
                            } else if (pageName === 'NewOrder') {
                                if (exsistingOrders === null || exsistingOrders === undefined) {
                                    hideCheckoutOffcanvas()
                                    localStorageFlag({localStorageUpdated: true})
                                    // showToast({toastVisible: true})
                                    setPaymentType({method: 'PlaceOrder'})
                                    updateCart([])
                                    setPhoneNumber({phone: null})
                                } else {
                                    exsistingOrders.push(tempCheckoutObj)
                                    hideCheckoutOffcanvas()
                                    localStorageFlag({localStorageUpdated: true})
                                    // showToast({toastVisible: true})
                                    setPaymentType({method: ''})
                                    updateCart([])
                                    setPhoneNumber({phone: null})
                                }
                            }
                        }
                    }
                })
            } else {
                if (pageName === 'NewOrder') {
                    // showPaymentOffcanvas({paymentOffcanvasVisible: true})
                    exsistingOrders = JSON.parse(localStorage.getItem('Orders'))
                }else if (pageName === 'purchase') {
                    exsistingOrders = JSON.parse(localStorage.getItem('Purchase'))
                } else if (pageName === 'wastage') {
                    exsistingOrders = JSON.parse(localStorage.getItem('Wastage'))
                }
                checkoutItemList.map(value => {
                    checkoutCartData.push({
                        "quantity":value.quantity,
                        "MRP":value.item.MRP,
                        "Cost":value.item.Cost,
                        "ItemTitle":value.item.ItemTitle,
                        "Item_ID":value.item.ID,
                        "Taxes":value.item.Taxes,
                        "Status":false,
                        "Image": value.item.ImageURL,
                        "AlternateText": value.item.AlternateText
                    })
                })
                tempCheckoutObj = {
                    "OfflineInvoiceNumber": "IN" + orderNumber,
                    "Orders":[{
                        "Items":checkoutCartData,
                        "TotalQty":totalQty,
                        "Subtotal":price.subtotal,
                        "TotalTax":{
                            "cgst": price.cgst,
                            "sgst":price.sgst
                        },
                        "Total":price.subtotal + price.sgst + price.cgst,
                        "Type":(checkoutType !== null) ? checkoutType : 'Counter Sale',
                        "DateTime":newDate,
                        "OrderNumber":orderNumber
                    }],
                    "Currency":"INR",
                    "Phone":(phone) ? phone : "",
                    "Date": onlyDate,
                    "UserName":"Hussain Kothari",
                    "BalanceAmount":price.subtotal + price.sgst + price.cgst - discountedAmount,
                    "PaymentMethod":"",
                    "OrderType": checkoutType,
                    "Amount": price.subtotal + price.sgst + price.cgst - discountedAmount,
                    "TransactionDate": newDate,
                    "Customer_ID": "",
                    "Status": 0,
                    "TotalDiscount": 0
                }
                console.log(tempCheckoutObj)
                // if (cartDraftID !== undefined && cartDraftID !== null && cartDraftID !== '') {
                //     let tempDraftData = JSON.parse(JSON.stringify(draftData))
                //     tempDraftData = tempDraftData.map((value,key) => {
                //         if (cartDraftID === value.ID) {
                //             value.ItemCount = tempCheckoutObj['Orders'][0].TotalQty
                //             value.cartItems = tempCheckoutObj
                //         }
                //         return value
                //     })
                //     localStorage.setItem('CartDraft', JSON.stringify(tempDraftData))
                //     cartDraftData({draftData: tempDraftData})
                //     // setCartDraftID({cartDraftID: null})
                // }
                if (pageName === 'purchase') {
                    if (exsistingOrders === null || exsistingOrders === undefined) {
                        localStorage.setItem('Purchase', JSON.stringify([tempCheckoutObj]))
                        hideCheckoutOffcanvas()
                        localStorageFlag({localStorageUpdated: true})
                        updateCart([])
                    } else {
                        exsistingOrders.push(tempCheckoutObj)
                        hideCheckoutOffcanvas()
                        localStorageFlag({localStorageUpdated: true})
                        localStorage.setItem('Purchase', JSON.stringify(exsistingOrders))
                        updateCart([])
                    }
                } else if (pageName === 'wastage') {
                    if (exsistingOrders === null || exsistingOrders === undefined) {
                        localStorage.setItem('Wastage', JSON.stringify([tempCheckoutObj]))
                        hideCheckoutOffcanvas()
                        localStorageFlag({localStorageUpdated: true})
                        updateCart([])
                    } else {
                        exsistingOrders.push(tempCheckoutObj)
                        hideCheckoutOffcanvas()
                        localStorageFlag({localStorageUpdated: true})
                        localStorage.setItem('Wastage', JSON.stringify(exsistingOrders))
                        updateCart([])
                    }
                } else if (pageName === 'NewOrder') {
                    console.log("IF", checkoutDraftType, draftIndex, odIndex)
                    if (fullInvoice === null || fullInvoice === undefined) {
                        // if (exsistingOrders === null || exsistingOrders === undefined || exsistingOrders.length === 0) {
                            if (checkoutDraftType !== 'draftCart') {
                                let tempObj = JSON.parse(JSON.stringify(draftData))
                                console.log(tempObj)
                                setPaymentType({method: 'PlaceOrder'})
                                if (draftIndex !== null && draftIndex !== undefined && draftIndex !== '') {
                                    // tempObj[draftIndex].Status = 0
                                    // tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].OrderStatus = 1
                                    tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].OrderDate = GetOrderdate();
                                    tempObj[draftIndex].OrderDate = GetOrderdate();
                                    if (tempObj[draftIndex].OfflineInvoiceNumber === '' || tempObj[draftIndex].OfflineInvoiceNumber === null || tempObj[draftIndex].OfflineInvoiceNumber === undefined) {
                                        tempObj[draftIndex].OfflineInvoiceNumber = "IN" + orderNumber 
                                    }
                                    if (tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].OrderNumber === null || tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].OrderNumber === undefined || tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].OrderNumber === '') {
                                        tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].OrderNumber = orderNumber
                                    }
                                    tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].DateTime = newDate
                                    tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Subtotal = 0
                                    tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Total = 0
                                    {tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items.map((value, key) => {
                                        tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Subtotal = tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Subtotal + (parseInt(value.MRP) * value.quantity)
                                        tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Total = tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Total + (parseInt(value.MRP) * value.quantity)
                                    })}
                                    tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Type = (checkoutType !== null && checkoutType !== undefined && checkoutType !== '') ? checkoutType : 'Counter Sale'
                                    tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].TotalQty = totalQty 
                                    tempObj[draftIndex].Date = onlyDate
                                }
                                let tempObj1 = JSON.parse(JSON.stringify(tempObj)) 
                                // console.log(tempObj1)
                                if (paymetMessage === '') {
                                    tempObj1[draftIndex].Status = 0
                                    tempObj1[draftIndex].Orders[(odIndex) ? odIndex : 0].OrderStatus = 1
                                    // tempObj1[draftIndex].DiscountType = (discountTypeString === 'percentage') ? totalDiscount + '%' : totalDiscount 
                                    // tempObj1[draftIndex].DiscountedAmount = discountedAmount
                                    // tempObj1[draftIndex].TotalDiscount = tempObj1[draftIndex].Amount - discountedAmount
                                    // tempObj1[draftIndex].DiscountTypeString = discountTypeString
                                    if (tempObj1[draftIndex]['Orders'][(odIndex) ? odIndex : 0].OrderStatus === 0) {
                                        tempObj1[draftIndex]['Orders'][(odIndex) ? odIndex : 0].kotNumber = parseInt(existingKotNumber) + 1
                                        tempObj1[draftIndex].Orders[(odIndex) ? odIndex : 0].DateTime = newDate
                                        localStorage.setItem('kotNumber', tempObj1[draftIndex]['Orders'][(odIndex) ? odIndex : 0].kotNumber)
                                    } else {
                                        if (tempObj1[draftIndex]['Orders'][(odIndex) ? odIndex : 0].kotNumber === undefined) {
                                            localStorage.setItem('kotNumber', parseInt(existingKotNumber) + 1)
                                        } 
                                        tempObj1[draftIndex]['Orders'][(odIndex) ? odIndex : 0].kotNumber = (tempObj1[draftIndex]['Orders'][(odIndex) ? odIndex : 0].kotNumber !== undefined) ? tempObj1[draftIndex]['Orders'][(odIndex) ? odIndex : 0].kotNumber : parseInt(existingKotNumber) + 1
                                    }
                                    if (tempObj1[draftIndex].billNo === undefined) {
                                        tempObj1[draftIndex].billNo = parseInt(existingBillNo) + 1
                                        // tempObj1[draftIndex].kotNumber = parseInt(existingKotNumber) + 1
                                        localStorage.setItem('billNo', parseInt(existingBillNo) + 1)
                                    }
                                    setKotData({kotData: tempObj1[draftIndex]['Orders'][(odIndex) ? odIndex : 0], printFrom: 'NewOrder'})   
                                    cartDraftData({draftData: tempObj1})
                                    localStorage.setItem('Orders', JSON.stringify([tempObj1[draftIndex]]))
                                    updateCart([])
                                    setPhoneNumber({phone: null})
                                    resetPrice('')
                                    cartDraftIndex({draftIndex: null})
                                    cartOrderIndex({odIndex : 0})
                                    localStorageFlag({localStorageUpdated: true})
                                    setDiscountDetails({discountType: null, discountedAmount: 0, totalDiscount: 0, discountTypeString: ''})
                                } else if (paymetMessage === 'collectPayment') {
                                    if (tempObj1[draftIndex].billNo === undefined) {
                                        tempObj1[draftIndex].billNo = parseInt(existingBillNo) + 1
                                        localStorage.setItem('billNo', parseInt(existingBillNo) + 1)
                                    }
                                    // tempObj1[draftIndex].DiscountType = (discountTypeString === 'percentage') ? totalDiscount + '%' : totalDiscount
                                    // tempObj1[draftIndex].DiscountedAmount = discountedAmount
                                    // tempObj1[draftIndex].TotalDiscount = tempObj1[draftIndex].Amount - discountedAmount
                                    // tempObj1[draftIndex].DiscountTypeString = discountTypeString
                                    console.log(tempObj1, tempObj1[draftIndex]?.BalanceAmount)
                                    setPaymentType({balanceAmount: tempObj1[draftIndex]?.BalanceAmount})
                                    setInvoiceID({invoiceID: tempObj1[draftIndex]?.OfflineInvoiceNumber})
                                    showPaymentOffcanvas({paymentOffcanvasVisible: true})
                                    hideCheckoutOffcanvas()
                                    cartDraftData({draftData: tempObj1})
                                    // setDiscountDetails({discountType: null, discountedAmount: 0, totalDiscount: 0})
                                }
                                setCartDraftID({cartDraftID: draftIndex})
                                // cartDraftIndex({draftIndex: null})
                                // cartOrderIndex({odIndex : 0})
                            }
                            setOfflineKotData({offlineKotData: tempCheckoutObj, orderIndex: 0})
                            // hideCheckoutOffcanvas()
                            // localStorageFlag({localStorageUpdated: true})
                            checkoutItemType({type: topScrollValue[0].name})
                        // } 
                        // else {
                        //     if (checkoutDraftType !== 'draftCart') {
                        //         exsistingOrders.push(tempCheckoutObj)
                        //         localStorage.setItem('Orders', JSON.stringify(exsistingOrders))
                        //         if (paymetMessage === '') {
                        //             // setKotData({kotData: tempObj[draftIndex]['Orders'][(odIndex) ? odIndex : 0], printFrom: 'NewOrder'})   
                        //         } else if (paymetMessage === '') {
                        //             // setPaymentType({balanceAmount: tempObj[draftIndex]?.BalanceAmount})
                        //             // setInvoiceID({invoiceID: tempObj[draftIndex]?.OfflineInvoiceNumber})
                        //             // showPaymentOffcanvas({paymentOffcanvasVisible: true})
                        //         }
                        //     } else if (checkoutDraftType === 'draftCart') {
                        //         localStorage.setItem('CartDraft', JSON.stringify(exsistingOrders))
                        //     }
                        //     if (paymetMessage === 'collectPayment') {
                        //         let tempObj1 = JSON.parse(JSON.stringify(draftData))
                        //         if (tempObj1[draftIndex].billNo === undefined) {
                        //             tempObj1[draftIndex].billNo = parseInt(existingBillNo) + 1
                        //             localStorage.setItem('billNo', parseInt(existingBillNo) + 1)
                        //         }
                        //         setPaymentType({balanceAmount: tempObj1[draftIndex]?.BalanceAmount})
                        //         setInvoiceID({invoiceID: tempObj1[draftIndex]?.OfflineInvoiceNumber})
                        //         showPaymentOffcanvas({paymentOffcanvasVisible: true})
                        //         cartDraftData({draftData: tempObj1})
                        //     }
                        //     setOfflineKotData({offlineKotData: tempCheckoutObj, orderIndex: 0})
                        //     hideCheckoutOffcanvas()
                        //     localStorageFlag({localStorageUpdated: true})
                        //     setPaymentType({method: ''})
                        //     updateCart([])
                        //     setPhoneNumber({phone: null})
                        //     resetPrice('')
                        //     checkoutItemType({type: topScrollValue[0].name})
                        // }
                    } else {
                        if (exsistingOrders === null || exsistingOrders === undefined || exsistingOrders.length === 0) {
                            const tempFullInvoice = JSON.parse(JSON.stringify(fullInvoice))
                            tempFullInvoice.Orders.push(tempCheckoutObj['Orders'][0])
                            tempFullInvoice.Amount = (discountTypeString === '') ? fullInvoice.Amount + tempCheckoutObj['Orders'][0]['Total'] : discountedAmount
                            tempFullInvoice.BalanceAmount = (discountTypeString === '') ? fullInvoice.BalanceAmount + tempCheckoutObj['Orders'][0]['Total'] : discountedAmount
                            localStorage.setItem('Orders', JSON.stringify([tempFullInvoice]))
                            setFullInvoice({fullInvoice: null})
                            setOfflineKotData({offlineKotData: tempFullInvoice, orderIndex: tempFullInvoice['Orders'].length - 1})
                        } else {
                            let alreadyExsistOrder = false
                            let arrKey
                            exsistingOrders.map((value,key) => {
                                if (value.OfflineInvoiceNumber === fullInvoice.OfflineInvoiceNumber) {
                                    alreadyExsistOrder = true
                                    arrKey = key
                                    return 
                                }
                            })
                            if (alreadyExsistOrder) {
                                exsistingOrders[arrKey].Amount = exsistingOrders[arrKey].Amount + tempCheckoutObj['Orders'][0]['Total']
                                exsistingOrders[arrKey].BalanceAmount = exsistingOrders[arrKey].BalanceAmount + tempCheckoutObj['Orders'][0]['Total']
                                exsistingOrders[arrKey]['Orders'].push(tempCheckoutObj['Orders'][0])
                                localStorage.setItem('Orders', JSON.stringify(exsistingOrders))
                                setFullInvoice({fullInvoice: null})
                                setOfflineKotData({offlineKotData: fullInvoice, orderIndex: exsistingOrders[arrKey]['Orders'].length - 1})
                            }
                        }
                        // showConfirmActionOffcanvas({confirmActionVisible: true})
                        localStorageFlag({localStorageUpdated: true})
                        // showToast({toastVisible: true})
                        setPaymentType({method: 'PlaceOrder'})
                        updateCart([])
                        setPhoneNumber({phone: null})
                        resetPrice('')
                        checkoutItemType({type: topScrollValue[0].name})
                    }
                }
            }
        }
    }

    const handleCollectPayment = () => {
        handlePaymentMethod('placeOrder', 'collectPayment')
    }
    
    let stockObj = {    
        "Items": [],
        "TotalQty": 0,
        "Subtotal": 0,
        "TotalTax": 0,
        "Total": 0,
        "DateTime": null,
        "Currency": "INR",
        "Phone": "",
        "Date": null,
        "UserName": "",
        "Business_ID": tokenData.userInfo.Business_ID,
        "Location_ID": tokenData.userInfo.DefaultLocationID,
        "StockType": "",
        "Customer_ID": 0
    }
    let tempItems = []
    let tempItemList = JSON.parse(JSON.stringify(items))
    const handleStockProceed = (type) => {
        stockItemList.map((v,k) => {
            tempItems.push({
                "quantity":v.quantity,
                "MRP":v.item.MRP,
                "Cost":v.item.Cost,
                "ItemTitle":v.item.ItemTitle,
                "Item_ID":v.item.ID,
                "Taxes":v.item.Taxes,
                "Status":false,
                "Image": v.item.ImageURL,
                "AlternateText": v.item.AlternateText
            })
        })
        if (type === 'addStock') {
            stockObj.StockType = 'addStock'
        } else if (type === 'removeStock') {
            stockObj.StockType = 'removeStock'
        }
        stockObj.Items = tempItems
        stockObj.DateTime = newDate
        stockObj.Date = onlyDate
        axios.put('/Stock', stockObj).then(res => {
            if (res.data.message === 'Success') {
                showAppToast({ toastMessage:'Success', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
                let tempStockQty = {}
                stockItemList.map((v,k) => {
                    tempStockQty[v.item.ID] = v.quantity
                })
                tempItemList.map((v,k) => {
                    if (tempStockQty.hasOwnProperty(v.ID)) {
                        if (type === 'addStock') {   
                            v.SalesQty = parseInt(v.SalesQty) + tempStockQty[v.ID]
                        } else if (type === 'removeStock') {
                            v.SalesQty = parseInt(v.SalesQty) - tempStockQty[v.ID]
                        }
                    }
                })
                itemsDataUpdate({items: tempItemList})
                // updateStockQuantity({stockItemIndex:stockItemIndex,quantity:0})
                updateStock([])
                updateStockPrice({
                    subtotal : 0,
                    cgst : 0,
                    sgst : 0})
            } else {
              showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000, isVisible: true})
            }
        })
    }
    useEffect(() => {
        if (stockItemList.length > 0) {
            setDisableProceedButton(false)
        } else {
            setDisableProceedButton(true)
        }
    }, [stockItemList])
    return (
        <div class="row">
            {stateName !== 'manageStock' &&
                <>
                    {configObj?.Config?.printKot &&
                        <div class="col-md-4 col-4 pe-0">
                            <button class={`btn btn-block btn-danger rounded-0 d-flex justify-content-center pe-0 ps-1`} onClick={() => {handlePaymentMethod('placeOrder', '')}} disabled={disableButton}>
                                <ion-icon name="print-outline"></ion-icon><div class="fw-bolder">KOT</div> 
                            </button>
                        </div>
                    }
                    <div class={(configObj?.Config?.printKot) ? "col-md-8 col-8 ps-0" : "col-md-12 col-12"}>
                        <button class={`btn btn-block btn-success rounded-0 d-flex justify-content-center pe-0 ps-1`} disabled={disableButton} onClick={handleCollectPayment}>
                            <ion-icon name="card-outline"></ion-icon><div class="fw-bolder">Payment</div>
                        </button>
                    </div>
                </>
            }
            {stateName === 'manageStock' &&
                <>
                    <div class="col-md-12 col-12">
                        <button class='btn btn-block rounded-0 d-flex justify-content-center pe-0 ps-1 text-white' style={{backgroundColor: stockActionColor}} onClick={() => {handleStockProceed(stockActionType)}} disabled={disableProceedButton}>
                            <ion-icon name="checkmark-circle-outline" class="text-white"></ion-icon><div class="fw-bolder">Proceed</div>
                        </button>
                    </div>
                </>
            }
        </div>
    )
}

const mapStateToProps = ({ checkoutItems, data, addUser, stock, auth, quantity }) => {
    const {
        checkoutItemList,
        price,
        tempSubtotal,
        tempBalance
    } = checkoutItems
    const {
        type,
        phone,
        activeTabName,
        triggerCheckoutFunction,
        method,
        balanceAmount,
        topScrollValue,
        invoiceID,
        invoiceNumber,
        fullInvoice,
        draftData,
        cartDraftID,
        checkoutFlagDraft,
        draftIndex,
        odIndex,
        offlineKotData,
        configObj,
        discountType,
        discountedAmount,
        totalDiscount,
        discountTypeString,
        items
    } = data
    const {
        userData,
        mobileNumberExist
    } = addUser
    const {
        stockActionColor, 
        stockActionType,
        stockItemList
    } = stock
    const {
        authToken
    } = auth
    const {
        checkoutItemIndex
    } = quantity
    return {
        checkoutItemList,
        price,
        type,
        phone,
        activeTabName,
        triggerCheckoutFunction,
        method,
        userData,
        mobileNumberExist,
        balanceAmount,
        topScrollValue,
        invoiceID,
        invoiceNumber,
        fullInvoice,
        draftData,
        cartDraftID,
        checkoutFlagDraft,
        tempSubtotal,
        tempBalance,
        draftIndex,
        odIndex,
        offlineKotData,
        configObj,
        discountType,
        discountedAmount,
        totalDiscount,
        discountTypeString,
        stockActionColor, 
        stockActionType,
        stockItemList,
        authToken,
        items,
        checkoutItemIndex
    }
  }
  
const mapDispatchToProps = {
    showPaymentOffcanvas,
    hideCheckoutOffcanvas,
    localStorageFlag,
    showToast,
    setPaymentType,
    updateCart,
    setPhoneNumber,
    resetPrice,
    checkoutItemType,
    showConfirmActionOffcanvas,
    setOfflineKotData,
    setFullInvoice,
    cartDraftData,
    setCartDraftID,
    setCheckoutFlagDraft,
    setRenderHeaderDraft,
    tempPrice,
    cartDraftIndex, 
    cartOrderIndex,
    setInvoiceID,
    setKotData,
    showShopStatusModal,
    setDiscountDetails,
    showAppToast,
    itemsDataUpdate,
    updateStockQuantity, 
    updateStock, 
    updateStockPrice
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutButton)
