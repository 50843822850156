import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import Logo from "../../images/logo.svg"
import { setInvoiceID, setPaymentType, setBusinessData } from '../../../features/Data/dataSlice'
import { showOngoingOffcanvas } from '../../../features/Offcanvas/ongoingOffcanvasSlice'
import { hideMainMenuOffcanvas } from '../../../features/Offcanvas/mainMenuOffcanvasSlice'
import { triggerBusinessdDataCall } from '../../../features/utilities/generalSlice'
import { showSwitchBusinessModal } from '../../../features/Offcanvas/switchBusinessModalSlice'
import { showShopStatusModal } from '../../../features/Offcanvas/shopStatusModalSlice'
import { logout } from '../../../features/auth/authSlice'

const CheckoutOffcanvas = ({
    setInvoiceID,
    invoiceCount,
    showOngoingOffcanvas,
    mainMenuOffcanvasVisible,
    hideMainMenuOffcanvas,
    authToken,
    setBusinessData,
    triggerBusinessdDataCall,
    showSwitchBusinessModal,
    showShopStatusModal,
    businessData,
    logout
}) => {
    const [headerTabName, setHeaderTabName] = useState('')
    const [queryString, setQueryString] = useState('')
    const [businessLogo, setBusinessLogo] = useState('')
    const [branchName, setBranchName] = useState('')
    const [businessName, setBusinessName] = useState('')
    const localStoragetToken = localStorage.getItem('token')
    const tokenData = jwt_decode(localStoragetToken)
    let navigate = useNavigate()
    const search = window.location.pathname.split('/')
    const tempShopStatus = localStorage.getItem('shopStatus')
    useEffect(() => {
        setHeaderTabName(search[1])
        setQueryString(window.location.search)
    }, [search])
    const handleNewOrderSwitch = () => {
        setInvoiceID({invoiceID: null})
        setPaymentType({balanceAmount: 0})
        hideMainMenuOffcanvas()
        navigate('/newOrder')
    }
    const handleOrderSwitch = (ongoing) => {
        if (ongoing === null || ongoing === undefined || ongoing === 0) {
            hideMainMenuOffcanvas()
            navigate('/orders?tab=sales&category=all')
        }
    }
    const handleStateSwitch = (stateName) => {
        if (stateName === 'summary') {
            navigate('/summary')
            hideMainMenuOffcanvas()
        } else if (stateName === 'items') {
            navigate('/items')
            hideMainMenuOffcanvas()
        } else if (stateName === 'transactions') {
            navigate('/transactions?type=income&tcategory=all')
            hideMainMenuOffcanvas()
        } else if (stateName === 'manageItems') {
            navigate('/manageItems')
            hideMainMenuOffcanvas()
        } else if (stateName === 'storeConfig') {
            navigate('/storeConfig')
            hideMainMenuOffcanvas()
        }
    }
    useEffect(() => {
        const tokenData = jwt_decode(authToken)
        setTimeout(() => {
            triggerBusinessdDataCall({triggerFunction: false})
        }, 5000);
        if (businessData) {
            businessData.map((value, key) => {
                value.Branches.map((v,k) => {
                    if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
                        setBranchName(v.Name)
                        setBusinessName(value.Name)
                        setBusinessLogo(value.Logo)
                    }
                })
            })   
        }
    }, [mainMenuOffcanvasVisible])
    const handleBusinessSwitch = () => {
        showSwitchBusinessModal({switchBusinessModalVisible: true})
    }
    const handleShopStatusModal = () => {
        showShopStatusModal({shopStatusModalVisible: true})
        hideMainMenuOffcanvas()
    }

    const handleLogout = () => {
        logout()
        setTimeout(() => {
            navigate('/')
        }, 1000)
    }

    const handlePageSwitch = (switchTo) => {
        if (switchTo === 'addSingleStock') {
            navigate('/manageStock?action=addStock')
            hideMainMenuOffcanvas()
        } else if (switchTo === 'removeSingleStock') {
            navigate('/manageStock?action=removeStock')
            hideMainMenuOffcanvas()
        } else if (switchTo === 'storeConfig') {
            navigate('/storeConfig')
            hideMainMenuOffcanvas()
        } else if (switchTo === 'userLogin') {
            navigate('/userLogin')
            hideMainMenuOffcanvas()
        }
    }
    return (
        <div>
            <Offcanvas show={mainMenuOffcanvasVisible} placement={'start'} style={{transition: '0.5s'}}>
                {/* <Offcanvas.Header class="offcanvas-header" style={{paddingTop: '0px', paddingBottom: '0px'}} closeButton>
                    <Offcanvas.Title style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <img src={Logo} className="img-fluid" alt="Brand" width="90px" />
                    </Offcanvas.Title>
                </Offcanvas.Header> */}
                <Offcanvas.Body class="pt-0 offcanvas-body p-0">
                    <div class="row w-100">
                        <div class="col-9 col-md-9">
                            <div class="profileBox bg-primary" style={{position: 'inherit'}}>
                                {(businessLogo !== null && businessLogo !== undefined) &&
                                    <div class="image-wrapper py-2">
                                        <img src={businessLogo} alt="image" class="imaged rounded" style={{objectFit: 'cover', width: '34px', height: '34px'}} />
                                    </div>
                                }
                                <div class="in py-2">
                                    <strong style={{fontSize: '12px'}}>{businessName}</strong>
                                    {tokenData?.userInfo?.RoleID !== '5' &&
                                        <div class="text-muted" style={{fontSize: '12px'}} onClick={handleBusinessSwitch}>
                                            <ion-icon name="location"></ion-icon>
                                            {branchName}
                                        </div>
                                    }
                                    {tokenData?.userInfo?.RoleID === '5' &&
                                        <div class="text-muted" style={{fontSize: '12px'}}>
                                            <ion-icon name="location"></ion-icon>
                                            {branchName}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="col-3 col-md-3 d-flex align-items-center justify-content-end">
                            <button class="btn btn-sm btn-transparent p-0" onClick={() => {hideMainMenuOffcanvas()}}>
                                <ion-icon name="close" class="m-0 text-light"></ion-icon>
                            </button>
                        </div>
                    </div>
                    <div style={{overflow: 'auto', height: '85vh'}}>
                        <ul class="listview image-listview flush no-line">
                            <li>
                                <a herf='javascript:void(0)' class={(headerTabName === 'summary') ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'} onClick={() => {handleStateSwitch('summary')}} style={{cursor: 'pointer'}}>
                                    <div class="icon-box bg-transparent">
                                        <ion-icon name="storefront-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>Summary</div>
                                        <span class="text-muted"></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href={'javascript:void(0)'} class={(headerTabName === 'items' || headerTabName === 'manageStock') ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'} onClick={() => {handleStateSwitch('items')}}>
                                    <div class="icon-box bg-transparent">
                                        <ion-icon name="cube-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>Stocks</div>
                                        <span class="text-muted"></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" onClick={() => {handleOrderSwitch(null)}} class={(headerTabName === 'orders') ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'}>
                                    <div class="icon-box bg-transparent">
                                        <ion-icon name="cart-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>Orders</div>
                                        <span class="text-muted">
                                            {invoiceCount > 0 &&
                                                <span class="badge bg-danger blink-ongoing-invoice">{invoiceCount}</span>
                                            }
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href={'javascript:void(0)'} class={(headerTabName === 'transactions') ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'} onClick={() => {handleStateSwitch('transactions')}}>
                                    <div class="icon-box bg-transparent">
                                        <ion-icon name="swap-vertical"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>Finance</div>
                                        <span class="text-muted"></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" onClick={handleNewOrderSwitch} class={(headerTabName === 'newOrder') ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'}>
                                    <div class="icon-box bg-primary">
                                        <ion-icon name="add-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>New Order</div>
                                        <span class="text-muted"></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div class="listview-title m-0">
                            <span>Manage</span>
                        </div>
                        <ul class="listview image-listview flush no-line">
                            <li>
                                <a href="javascript:void(0)" class={(headerTabName === 'manageItems' || headerTabName === 'itemProfile') ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'} style={{cursor: 'pointer'}} onClick={() => {handleStateSwitch('manageItems')}}>
                                    <div class="icon-box bg-transparent">
                                        <ion-icon name="cube-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>Items</div>
                                        <span class="text-muted"></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" class={(headerTabName === 'manageStock' && queryString.includes('addStock')) ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'} style={{cursor: 'pointer'}} onClick={() => {handlePageSwitch('addSingleStock')}}>
                                    <div class="icon-box bg-transparent">
                                        <ion-icon name="bag-add-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>Add Stock</div>
                                        <span class="text-muted"></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" class={(headerTabName === 'manageStock' && queryString.includes('removeStock')) ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'} style={{cursor: 'pointer'}} onClick={() => {handlePageSwitch('removeSingleStock')}}>
                                    <div class="icon-box bg-transparent">
                                        <ion-icon name="bag-remove-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>Remove Stock</div>
                                        <span class="text-muted"></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div class="listview-title m-0">
                            <span>Reports</span>
                        </div>
                        <ul class="listview image-listview flush no-line">
                            <li>
                                <a href='javascript:void(0)' class={'item pt-0 pb-0'} style={{cursor: 'pointer'}}>
                                    <div class="icon-box bg-transparent">
                                        <ion-icon name="pie-chart-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>Sales Report</div>
                                        <span class="text-muted"></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="sidebar-buttons" style={{height: '40px'}}>
                        <a href="javascript:void(0)" class="button" onClick={() => {handlePageSwitch('userLogin')}} style={{height: '40px'}}>
                            <ion-icon name="checkmark-circle-outline" class="fs-4"></ion-icon>
                        </a>
                        {/* <a href="javascript:void(0)" class="button" onClick={() => {handlePageSwitch('removeSingleStock')}} style={{height: '40px'}}>
                            <ion-icon name="bag-remove-outline" class="fs-4"></ion-icon>
                        </a> */}
                        <a href="javascript:void(0)" class="button" onClick={() => {handlePageSwitch('storeConfig')}} style={{height: '40px'}}>
                            <ion-icon name="settings-outline" class="fs-4"></ion-icon>
                        </a>
                        <a href="javascript:void(0)" class="button" onClick={handleLogout} style={{height: '40px'}}>
                            <ion-icon name="log-out-outline" class="fs-4"></ion-icon>
                        </a>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

const mapStateToProps = ({ data, mainMenu, auth }) => {
    const {
        invoiceCount,
        businessData
    } = data
    const {
        mainMenuOffcanvasVisible
    } = mainMenu
    const {
        authToken
    } = auth
    return {
        invoiceCount,
        mainMenuOffcanvasVisible,
        authToken,
        businessData
    }
  }
  
  const mapDispatchToProps = {
    setInvoiceID,
    setPaymentType,
    showOngoingOffcanvas,
    hideMainMenuOffcanvas,
    setBusinessData,
    triggerBusinessdDataCall,
    showSwitchBusinessModal,
    showShopStatusModal,
    logout
  }

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutOffcanvas)